import { isNull } from "lodash-es";

function getUrlParam(key: string, url?: string) {

    url = url || window.location.href;
    const urlObj = new URL(url);
    const params = urlObj.searchParams;

    // 获取指定的参数值，如果参数不存在或为空，则返回null
    const value = params.has(key) ? params.get(key) : null;

    // 检查空参数，例如参数值为''或参数存在但值为空格
    if (value === '' || isNull(value)) {
        return '';
    }

    // 解码URL编码的字符
    try {
        return decodeURIComponent(value);
    } catch (error) {
        // 如果解码失败，返回原始值
        console.error(`Decoding error for key "${key}": `, error);
        return value;
    }

}



export { getUrlParam }