import { useReducer } from "react";
import { useRequest } from "ahooks";

import { userState, userState_memorize } from "./state";
import { UserStore, UserDispatchStore, useUserDispatchStore } from "./context";
import { reducer } from "./reducer";
import { getUserInfo } from "src/request/user";
import { loginOut } from "src/request/login/login-out";
import { goto } from "src/WithRouter";
import {
  checkIsFromExpand,
  dataWarehouse,
  getOption,
} from "@utils/operation-correlation";
import { logger } from "@utils/logger";

function UserProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer<any>(reducer, userState);

  return (
    <UserStore.Provider value={state!}>
      <UserDispatchStore.Provider value={dispatch}>
        <UserInit />
        {children}
      </UserDispatchStore.Provider>
    </UserStore.Provider>
  );
}

function UserInit() {
  const userDispatchStore = useUserDispatchStore();

  const { run: runLoginOut } = useRequest(
    () => {
      return loginOut();
    },
    {
      manual: true,
      onSuccess(res) {
        const { code, data } = res;
        if (code === 0) {
          goto("/login");
        }
      },
      onError(err) {
        console.error("loginOut ->> ", err);
      },
    }
  );

  useRequest(
    () => {
      return getUserInfo();
    },
    {
      onSuccess(res) {
        const { code, data } = res;

        if (code === 0) {
          if (
            data &&
            data.accountStatus &&
            data.accountStatus !== I_User.ACCOUNT_STATUS.normal
          ) {
            // 身份异常
            runLoginOut();
          }

          checkIsFromExpand();
          window.GA_UID = data?._id;

          userDispatchStore({
            type: "login",
            payload: {
              ...data,
              isLoading: false,
            },
          });
          Object.assign(userState_memorize, data);
        } else {
          console.log("没有登录");
          userDispatchStore({
            type: "init",
            payload: {
              isLoading: false,
            },
          });
        }
      },
      onError(err) {
        logger.log("error", `getUserSetting error ->> ${err}`);
      },
      onFinally() {
        dataWarehouse(getOption("banner", { viewBanner: true }));
      },
    }
  );

  return <></>;
}

export { UserProvider };
