import { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import cn from "classnames";

import RegisterBtn from "@pages/Home/Components/registerBtn";
import LazyImg from "@components/LazyImg";
import { isMobile } from "@utils/is";
import { useUserStore } from "src/store/user";

import light from "./img/guide-drag-light.png";
import dark from "./img/guide-drag-dark.png";

import styles from "./index.module.less";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

function GuideFooter() {
  const dragMask = useRef<HTMLDivElement>(null);
  const windowDom = useRef(null);
  const { _id } = useUserStore();
  const isLogin = !!_id;

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: windowDom.current,
          toggleActions: "play none none reset",
          scrub: true,
          start: "top +=100%",
          end: "top +=60%",
        },
      })
      .to(windowDom.current, {
        yPercent: -60,
      });
  }, [window.location.pathname]);

  function dragHandle(e: any) {
    let maskWidth = dragMask.current?.getBoundingClientRect().width || 0;
    let maskX = dragMask.current?.getBoundingClientRect().x || 0;
    let u = maskX - e.clientX;

    dragMask.current && (dragMask.current.style.width = maskWidth + u + "px");
  }

  function dragLeaveHandle() {
    dragMask.current && (dragMask.current.style.width = "80.8%");
  }

  return (
    <section className={styles.wrapper}>
      <div
        className={cn({
          "view-1": true,
          [styles["guide-wrap"]]: true,
        })}
      >
        <div
          className={cn({
            "js-build-in": true,
            "build-in-scale-fade": true,
            [styles.left]: true,
          })}
          style={{
            maxWidth: "370px",
          }}
        >
          <h2>
            <strong>
              次世代
              <br />
            </strong>
            在线设计工具
          </h2>
          <p>在同一个地方，构思、设计、协作， 让你和团队更加高效。</p>
          <RegisterBtn
            className={isLogin ? styles.loginedBtn : styles.btn}
            useBtnIsInSide={isMobile.phone ? false : true}
            isShowPrivateBtn
          />
        </div>
        <div
          className={styles.right}
          style={{
            backgroundImage: `url(${window.jsStaticUrl}/assets/Resources/background/home-bg-2.png)`,
          }}
        >
          <span className={styles["bg-follow"]}></span>
          <div
            className={styles["right_drag_wrap"]}
            ref={windowDom}
            onMouseMove={dragHandle}
            onMouseLeave={dragLeaveHandle}
          >
            <div
              className={cn({
                [styles["guide-img"]]: true,
              })}
            >
              {/* <LazyImg src={light} className={"width-full"} /> */}
              <img src={light} className={"width-full"} />
            </div>
            <div className={styles["drag-mask"]} ref={dragMask}>
              <div
                className={cn({
                  [styles["guide-img"]]: true,
                })}
              >
                {/* <LazyImg src={dark} className={"width-full"} /> */}
                <img src={dark} className={"width-full"} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GuideFooter;
