import { CSSProperties, useMemo, ReactNode } from "react";
import { noop } from "lodash-es";
import cn from "classnames";

import styles from "./index.module.less";

interface I_Props {
  id?: string;
  text?: string;
  style?: CSSProperties;
  className?: string;
  loading?: boolean;
  disabled?: boolean;
  children?: ReactNode;
  onClick?: (...args: any[]) => void;
}

function PrimaryButton(props: I_Props) {
  const {
    id = "primary-button",
    className = "",
    text,
    disabled = false,
    loading = false,
    onClick = noop,
    children,
    style = {},
    ...otherProps
  } = props;

  const curDisabled = useMemo(() => {
    return disabled || loading;
  }, [disabled, loading]);

  const dynamicStyle = useMemo(() => {
    return {
      pointerEvents: curDisabled ? "none" : "auto",
    } as CSSProperties;
  }, [curDisabled]);

  const allStyle = useMemo(() => {
    return { ...style, ...dynamicStyle };
  }, [style, dynamicStyle]);

  return (
    <button
      id={id}
      data-type="button"
      className={cn({
        [`${className}`]: className,
        [styles["primary-button"]]: true,
        [styles["button-disabled"]]: curDisabled,
      })}
      onClick={onClick}
      disabled={curDisabled}
      style={allStyle}
      {...otherProps}>
      {children || text}
    </button>
  );
}

export default PrimaryButton;
