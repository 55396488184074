import { Suspense, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSPlugin } from "gsap/CSSPlugin";

import Footer from "@components/Footer";
import Help from "@components/Help";
import BackTop from "@components/BackTop";
import { isMobile } from "@utils/is";
import GuideFooter from "@components/GuideFooter";
import { useListenElementsShow } from "@utils/hooks";
import Compare from "./Components/Compare";
import Resource from "./Components/Resource";
import Workflow from "./Components/Workflow";
import WhyUs from "./Components/WhyUs";
import HighLight from "./Components/HighLight";
import UpdatedLog from "./Components/UpdatedLog";
import FreeUse from "./Components/FreeUse";
import FourthScreen from "./Components/FourthScreen";
import PageContainer from "@components/PageContainer";
import HeroMotion from "./Components/HeroMotion";
import Feature from "./Components/Feature";
import styles from "./index.module.less";
import NotificationsPanel from "@components/NotificationsPanel";
import { NativeLoading } from "@components/NativeLoading ";
import { useUserStore } from "src/store/user";
import { useNavigate } from "react-router-dom";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger, CSSPlugin);
}

function Home() {
  useListenElementsShow({
    isAllEntries: true,
    getElementsFunc: () => {
      if (isMobile.phone) return null;
      const elements = document.querySelectorAll(".js-build-in");
      return Array.from(elements) as HTMLElement[];
    },
    callback: (entries) => {
      for (const entry of entries) {
        entry.target.classList.toggle("build-in-animate", entry.isIntersecting);
      }
    },
    dependencies: [window.location.pathname],
  });

  useEffect(() => {
    const heroBgChangeTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".features-wrap",
        start: "-60%",
        end: "-60%",
        toggleActions: "play none none reset",
      },
    });

    heroBgChangeTl.to("main", {
      background: "hsl(210, 51%, 97%)",
      duration: 0.4,
      ease: "power2.inOut",
    });

    if (typeof window !== "undefined") {
      const windowWidth = window.innerWidth;

      if (windowWidth < 640) {
        heroBgChangeTl.kill();
        const heroBgChangeMobileTl = gsap.timeline({
          scrollTrigger: {
            trigger: ".features",
            start: "-20%",
            end: "-20%",
            toggleActions: "play none none reset",
            markers: false,
          },
        });
        heroBgChangeMobileTl.to("main", {
          background: "hsl(210, 51%, 97%)",
          duration: 0.4,
          ease: "power2.inOut",
        });
      }
    }
  }, []);
  return (
    <PageContainer>
      <div className={isMobile.phone ? styles.homeMobile : styles.home}>
        <Suspense fallback={<NativeLoading />}>
          <NotificationsPanel />
        </Suspense>
        <main className={styles.body}>
          <HeroMotion />
          <div className={styles.container}>
            <div
              style={{
                maxWidth: "1440px",
                margin: "0 auto",
              }}
            >
              <Feature className="features-wrap" />
              <FourthScreen />
              <FreeUse />
              <HighLight />
              <Workflow />
              <WhyUs />
            </div>
            <Resource />
            <Compare />
            <UpdatedLog />
          </div>
        </main>
        <footer className={styles.footerContainer}>
          <GuideFooter />
          <Footer />
        </footer>
        <BackTop />
        {!isMobile.phone && <Help />}
      </div>
    </PageContainer>
  );
}

interface WrapperHomeProps {}
const isHome = window.location.pathname.includes("/home");
// 处理登录后重定向
export default (props: WrapperHomeProps) => {
  const { _id, isLoading } = useUserStore();
  const go = useNavigate();
  useEffect(() => {
    // console.log("监听用户数据状态", _id, isLoading);
    if (!isLoading && !isHome) {
      _id ? go("/workspace") : "";
    }
  }, [isLoading, _id]);
  if (isLoading || (_id && !isHome)) {
    return "";
  }
  return <Home {...props} />;
};
