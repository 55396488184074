function reducer(
  state: I_User.State,
  action: I_User.Actions
): Partial<I_User.State> {
  switch (action.type) {
    case "login": {
      const { payload } = action;
      return payload;
    }
    case "init": {
      const { payload } = action;
      return { ...payload } as Partial<I_User.State>;
    }
  
    case "_id": {
      const { payload } = action;
      console.log("payload", payload);

      return { ...payload } as Partial<I_User.State>;
    }

    default: {
      console.error("userReducer 未实现的type ->> ", action);
      return state;
    }
  }
}

export { reducer };
