import instance from "./base";
import { I_RequestParams, I_ResponseData } from "./types";

export const get = <T = any>({
  url = "",
  data,
  config = {},
}: I_RequestParams) => {
  return new Promise((resolve: (value: I_ResponseData<T>) => void, reject) => {
    instance
      .get(url, { ...config, ...(data ? { params: data } : {}) })
      .then((response: any) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
