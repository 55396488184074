import { useEffect, useState } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { isMobile } from "@utils/is";

import styles from "./index.module.less";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}
const useInitGsap = (onHeroSectionStart: gsap.Callback) => {
  const [heroTlOuter, setHeroTlOuter] = useState(gsap.timeline({}));

  useEffect(() => {
    let gridTl = gsap.timeline({
      ease: "power2.out",
    });

    // -------- GRID --------------//
    gridTl
      .fromTo(
        ".hero-section-bg",
        {
          scale: 4,
          onStart: onHeroSectionStart,
        },
        {
          duration: 2,
          ease: "power3.out",
          scale: 1,
        },
        "start"
      )
      .from(
        ".slogan-wrap h1,.slogan-wrap .des,.slogan-wrap .form",
        {
          stagger: 0.2,
          duration: 0.4,
          autoAlpha: 0,
          y: 80,
        },
        "< +0.2"
      )
      .to(
        ".window-wrap",
        {
          duration: 0.7,
          opacity: 1,
          y: "-50vh",
        },
        "<"
      );

    // -------- SCROLL ------------//
    if (isMobile.phone) return;

    let heroTl = gsap.timeline({ paused: true });
    heroTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".hero-section",
        start: "-=88",
        // start: 0,
        scrub: 0,
        toggleActions: "play none none reset",
        markers: false,
        pin: true,
        pinSpacing: true,
      },
    });

    heroTl
      .addLabel("start")
      .to(
        ".slogan",
        {
          y: "-100%",
          opacity: 0,
        },
        "start"
      )
      .to(
        ".hero-section-bg",
        {
          opacity: 0.85,
        },
        "start"
      )
      .to(
        ".window-wrap",
        {
          yPercent: -49,
          scale: 0.85,
          delay: 0.06,
        },
        "start"
      )
      .to(
        ".scroll-title",
        {
          opacity: 1,
          visibility: "visible",
          delay: 0.6,
        },
        "start"
      );
    heroTl
      .addLabel("window-1")
      .to(
        ".window-wrap",
        {
          x: "25vw",
        },
        "window-1"
      )
      .to(
        ".scroll-title",
        {
          x: 0,
          opacity: 1,
          duration: 1.4,
        },
        "window-1"
      );

    heroTl
      .addLabel("window-2")
      .to(
        ".scroll-title_tab-item-2",
        {
          className: styles["gsap-scroll-title_tab-active"],
        },
        "window-2"
      )
      .to(
        ".hero-video-1",
        {
          className: `video-wrap ${styles["gsap-video-wrap"]} ${styles["gsap-hero-video-hidden"]}`,
        },
        "window-2"
      )
      .to(
        ".hero-video-2",
        {
          className: `video-wrap ${styles["gsap-video-wrap"]} ${styles["gsap-hero-video-show"]}`,
        },
        "window-2"
      )
      .to(
        ".scroll-title_tab-item-1",
        {
          className: "scroll-title_tab-item-1",
        },
        "window-2"
      )
      .to(
        ".scroll-title_content-item-1",
        {
          className: `scroll-title_content ${styles["gsap-scroll-title_content"]} scroll-title_content-item-1`,
        },
        "window-2"
      )
      .to(
        ".scroll-title_content-item-2",
        {
          className: `scroll-title_content ${styles["gsap-scroll-title_content"]} ${styles["gsap-scroll-title_content-active"]}`,
        },
        "window-2"
      );
    heroTl
      .addLabel("window-3")
      .to(
        ".scroll-title_tab-item-3",
        {
          className: styles["gsap-scroll-title_tab-active"],
        },
        "window-3"
      )
      .to(
        ".hero-video-3",
        {
          className: `video-wrap ${styles["gsap-video-wrap"]} ${styles["gsap-hero-video-show"]}`,
        },
        "window-3"
      )
      .to(
        ".scroll-title_tab-item-2",
        {
          className: "scroll-title_tab-item-2",
        },
        "window-3"
      )
      .to(
        ".scroll-title_content-item-2",
        {
          className: `scroll-title_content ${styles["gsap-scroll-title_content"]} scroll-title_content-item-2`,
        },
        "window-3"
      )
      .to(
        ".scroll-title_content-item-3",
        {
          className: `scroll-title_content ${styles["gsap-scroll-title_content"]} ${styles["gsap-scroll-title_content-active"]}`,
        },
        "window-3"
      )
      .to(".hero-section", {
        yPercent: -5,
      });

    setHeroTlOuter(heroTl);
  }, []);

  function seekHeroTl(time: number) {
    heroTlOuter.progress(time);
  }

  return { seekHeroTl };
};

export default useInitGsap;
