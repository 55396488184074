import { useReducer } from "react";

import { notificationsState } from "./state";
import { NotificationsContext, NotificationsDispatchContext } from "./context";
import reducer from "./reducer";
import { I_NotificationsContent } from "../type";

function NotificationsProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(
    reducer,
    notificationsState
  );

  return (
    <NotificationsContext.Provider
      value={state as I_NotificationsContent}>
      <NotificationsDispatchContext.Provider value={dispatch}>
        {children}
      </NotificationsDispatchContext.Provider>
    </NotificationsContext.Provider>
  );
}

export { NotificationsProvider };
