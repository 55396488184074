import Button, { ButtonProps } from "@components/Button";
import { useNavigate } from "react-router-dom";
import { useUserStore } from "src/store/user";
import styles from "./index.module.less";
import InlineSVG from "svg-inline-react";
import { icon_phone } from "@svg/home";
import { CSSProperties, useState } from "react";
import Lark from "@svg/lark.svg";
import WeChat from "@svg/we_chat.svg";
import LazyImg from "@components/LazyImg";
import PrimaryButton from "@components/PrimaryButton";
import { isMobile } from "@utils/is";

interface RegisterBtnProps extends Partial<ButtonProps> {
  loginBtnStyle?: CSSProperties;
  registerBtnStyle?: CSSProperties;
  className?: string;
  useBtnIsInSide?: boolean;
  isShowPrivateBtn?: boolean;
  // 控制登录描述是否开启最大限制
  maxDescribe?: boolean;
}

const commonButonStyle = {
  width: "174px",
  height: "60px",
  lineHeight: "60px",
  padding: 0,
  textAlign: "center",
  color: "white",
} as CSSProperties;

export const FEI_SHU_LOGIN_LINK: any = `https://open.feishu.cn/open-apis/authen/v1/index?redirect_uri=${encodeURIComponent(
  `${window.jsUrl}/authen`
)}&app_id=cli_9f35e3a3cb3a500e&state=feishu&behaviour=login`;

const RegisterBtn = (props: RegisterBtnProps) => {
  const {
    className,
    useBtnIsInSide = true,
    isShowPrivateBtn = false,
    style,
    maxDescribe = true,
  } = props;
  // 用户id
  const { _id } = useUserStore();
  // 注册手机号
  const [phoneNum, setPhoneNum] = useState("");
  const isLogin = _id;
  const isPhone = isMobile.phone;
  const n = useNavigate();
  const handleGoto = () => {
    n(
      isLogin
        ? "/workspace"
        : `/login?type=register&by=phone${phoneNum ? "&m=" + phoneNum : ""}`
    );
  };

  const handleGoPrivatization = () => {
    n("/privatization");
  };

  if (isPhone && isLogin) return <></>;

  if (isLogin) {
    return (
      <div className={`${className ? className : ""} form ${styles.logined}`}>
        <div className={styles.btnList}>
          <div className="hero-cta">
            <PrimaryButton
              style={style ? style : isShowPrivateBtn ? commonButonStyle : {}}
              className="fix-content"
              text="免费使用"
              onClick={handleGoto}
            />
          </div>
          {isShowPrivateBtn ? (
            <Button
              onClick={() => handleGoPrivatization()}
              style={{
                ...commonButonStyle,
                backgroundColor: "rgb(36, 48, 66)",
              }}
            >
              咨询私有化部署
            </Button>
          ) : (
            ""
          )}
        </div>
        <p
          className={`${styles.describe} describe ${
            maxDescribe ? styles.maxdescribe : ""
          }`}
        >
          支持网页端、macOS、Windows、Linux、iOS、Android 和微信小程序
        </p>
      </div>
    );
  }
  return (
    <div className={`${className ? className : ""} form`}>
      <div>
        <Button className={styles["input-wrapper"]}>
          <InlineSVG src={icon_phone} />
          <input
            type="text"
            placeholder="输入手机号注册"
            onChange={(e) => setPhoneNum(e.target.value)}
          />
          {useBtnIsInSide && (
            <div className="hero-cta">
              <PrimaryButton
                className="fix-content"
                text="免费使用"
                onClick={handleGoto}
              />
            </div>
          )}
        </Button>
        {isShowPrivateBtn && (
          <Button
            onClick={() => handleGoPrivatization()}
            style={{
              ...commonButonStyle,
              marginTop: "16px",
              width: "100%",
              textAlign: "center",
              backgroundColor: "rgb(36, 48, 66)",
            }}
          >
            咨询私有化部署
          </Button>
        )}
        {!useBtnIsInSide && (
          <div className="hero-cta">
            <PrimaryButton
              className="fix-content"
              text="免费使用"
              onClick={handleGoto}
            />
          </div>
        )}
      </div>
      <div className={styles.social}>
        <div onClick={() => n("/login?type=wechatLogin")}>
          <LazyImg src={WeChat} />
          <p>微信登录</p>
        </div>
        <div onClick={() => (window.location.href = FEI_SHU_LOGIN_LINK)}>
          <LazyImg src={Lark} />
          <p>飞书登录</p>
        </div>
      </div>
      <p
        style={{
          margin: "0 auto",
          // maxWidth: "370px",
        }}
        className={`${styles.describe} describe ${
          maxDescribe ? styles.maxdescribe : ""
        }`}
      >
        支持网页端、macOS、Windows、Linux、iOS、 Android 和微信小程序
      </p>
    </div>
  );
};

export default RegisterBtn;
