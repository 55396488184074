function getOS() {
    let result = "";
    const userAgent = navigator.userAgent;

    const platform = String(navigator.platform);
    switch (true) {
        case platform === "Mac68K":
        case platform === "MacPPC":
        case platform === "Macintosh":
        case platform === "MacIntel":
            result = "Mac";
            break;
        case userAgent.includes("Windows NT 5.0") || userAgent.includes("Windows 2000"):
            result = "Win2000";
            break;
        case userAgent.includes("Windows NT 5.1") || userAgent.includes("Windows XP"):
            result = "WinXP";
            break;
        case userAgent.includes("Windows NT 5.2") || userAgent.includes("Windows 2003"):
            result = "Win2003";
            break;
        case userAgent.includes("Windows NT 6.0") || userAgent.includes("Windows Vista"):
            result = "WinVista";
            break;
        case userAgent.includes("Windows NT 6.1") || userAgent.includes("Windows 7"):
            result = "Win7";
            break;
        case userAgent.includes("Windows NT 6.2") || userAgent.includes("Windows 8"):
            result = "Win8";
            break;
        case userAgent.includes("Windows NT 6.3") || userAgent.includes("Windows 8.1"):
            result = "Win8.1";
            break;
        case userAgent.includes("Windows NT 10.0") || userAgent.includes("Windows 10"):
            result = "Win10";
            break;
        case userAgent.includes("Windows NT 11.0") || userAgent.includes("Windows 11"):
            result = "Win11";
            break;
        case platform === 'Win32':
        case platform === 'Windows':
            result = "Windows";
            break;
        case platform.includes("Linux"):
            result = "Linux";
            break;
        case platform === 'X11':
            result = "Unix";
            break;
        case platform === 'Android':
            result = "Android";
            break;
        case platform === 'iPhone':
        case platform === 'iPad':
            result = "IOS";
            break;
        default:
            result = "other";
            break;
    }

    return result;
}

export {
    getOS
}