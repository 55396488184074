import { useEffect, useState } from "react";
import { AppOptions } from "../../utils/garfish/app";
import Garfish, { interfaces } from "garfish";

const useLoadApp = ({
  appInfo,
  name,
}: {
  appInfo: AppOptions;
  name: string;
}) => {
  const [app, setApp] = useState<interfaces.App | null>(null);
  useEffect(() => {
    (async () => {
      let app = await Garfish.loadApp(name, appInfo);
      console.info("调试app", name, appInfo);
      setApp(app);
    })();
  }, []);
  return app;
};
export default useLoadApp;
