import { getObjLength } from "@utils/get";

function isObj(_obj: any): _obj is Object {
    return Object.prototype.toString.call(_obj) === '[object Object]'
}

function isEmptyObj(_obj: any) {
    return isObj(_obj) && getObjLength(_obj) === 0;
}


export {
    isObj,
    isEmptyObj
}