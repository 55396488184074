import { post } from "@utils/https";

const handleGetCopyList = ({ pageNum = 1, pageSize = 2, sortType = "" }) => {
  const data = {
    pageNum,
    pageSize,
    sortType,
  };

  return post({
    url: "/activity/list",
    data,
  });
};

const handleGetClockList = ({
  pageNum = 1,
  pageSize = 1,
  sortType = "",
  needPeopleNo = true,
}) => {
  const data = {
    pageNum,
    pageSize,
    sortType,
    needPeopleNo,
  };

  return post({
    url: "/punchCard/list",
    data,
  });
};

export { handleGetCopyList, handleGetClockList };
