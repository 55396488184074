import { createContext, Dispatch, useContext } from "react";

import { I_NotificationsContent, I_NotificationsActions } from "../type";

const NotificationsContext =
  createContext<I_NotificationsContent | null>(null);

const NotificationsDispatchContext = createContext<Dispatch<I_NotificationsActions> | null>(null);

function useNotifications() {
  return useContext(NotificationsContext) as I_NotificationsContent;
}

function useNotificationsDispatch() {
  return useContext(NotificationsDispatchContext) as Dispatch<I_NotificationsActions>;
}

export {
  NotificationsContext,
  NotificationsDispatchContext,
  useNotifications,
  useNotificationsDispatch,
};
