import { checkIsNotReferrer } from "@utils/window/judge";

function checkIsDingTalkPc() {
  if (checkIsNotReferrer()) return false;
  const { userAgent } = window.navigator;

  return userAgent.toLowerCase().indexOf("dingtalk") !== -1;
}

function checkIsInFeiShuPc() {
  if (checkIsNotReferrer()) return false;
  const { userAgent } = window.navigator;

  return userAgent.toLowerCase().indexOf("lark") !== -1;
}

function checkIsInDingTalkPcOrFeiShuPc() {
  return checkIsDingTalkPc() || checkIsInFeiShuPc();
}

export { checkIsDingTalkPc, checkIsInFeiShuPc, checkIsInDingTalkPcOrFeiShuPc };
