import { I_NotificationsContent, I_NotificationsActions } from "../type";

function reducer(
  status: I_NotificationsContent,
  action: I_NotificationsActions
): I_NotificationsContent {
  switch (action.type) {
    case "toggleShow": {
      const { payload } = action;
      const { isShow } = payload;
      const { isShowDropFilter } = status;
      return { ...status, ...payload, isShowDropFilter: isShow ? isShowDropFilter : false };
    }
    case "changeType": {
      const { payload } = action;
      const { type } = payload;
      return { ...status, type, isShowDropFilter: false };
    }
    case "changeIsHaveNoReadMessage": {
      const { payload } = action;
      const { community, official, project, team } = payload;
      const readAllMessageStatus =
        community + official + project + team > 0 ? true : false;

      return {
        ...status,
        readAllMessageStatus,
        readCommunityStatus: community > 0 ? true : false,
        readOfficialStatus: official > 0 ? true : false,
        readProjectStatus: project > 0 ? true : false,
        readTeamStatus: team > 0 ? true : false,
      };
    }
    case "changeIsShowDropFilter": {
      const {
        payload: { isShow },
      } = action;

      return {
        ...status,
        isShowDropFilter: isShow,
      };
    }
    case "changeGetNotificationsListFlag": {
      const {
        payload: { getNotificationsListFlag },
      } = action;
      return { ...status, getNotificationsListFlag };
    }
    case "changeConfigObj": {
      const {
        payload: { configObj },
      } = action;
      return { ...status, configObj };
    }
    default: {
      console.log(status, action);

      throw Error("Unknown action: ");
    }
  }
}

export default reducer;
