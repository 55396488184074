import { post } from "@utils/https";

export enum LOGIN_OUT_TYPE {
  // 退出全部账号
  all = 0,
  // 退出当前账号
  current = 1,
}

type T_Options = {
  type: LOGIN_OUT_TYPE;
};

function loginOut(options = {} as T_Options) {
  return post({
    url: "/api/user/v1/logout",
    data: options,
  });
}

export { loginOut };
