import { lazy } from "react";
import { RouteProps } from "react-router-dom";

type RoutePropsComponent = RouteProps;

/**
 * 使用适配器临时将lazy返回值强行适配react-router的Route组件的component属性，防止ts类型错误
 *
 * TODO: 后期 想要完美解决这块的ts类型问题，需要将react和react-router升级到最新版本，并经过完整严格测试
 * @param params
 * @returns
 */
const lazyAdapter = (
  ...params: Parameters<typeof lazy>
): RoutePropsComponent => {
  return lazy(...params) as RoutePropsComponent;
};

export { lazyAdapter };
