import { isObject } from "lodash-es";
import ai_mobile from "@svg/ai_mobile.svg";
import ai_pc from "@svg/ai_web.svg";
import a1_img_1 from "@img/a1/a1-1.png";
import a1_img_2 from "@img/a1/a1-2.png";
import a1_img_3 from "@img/a1/a1-3.png";
import a1_img_4 from "@img/a1/a1-4.png";
import a1_img_5 from "@img/a1/a1-5.png";
import a1_img_6 from "@img/a1/a1-6.png";
import a1_img_7 from "@img/a1/a1-7.png";
import a1_img_8 from "@img/a1/a1-8.png";
import a1_img_9 from "@img/a1/a1-9.png";
import a1_img_10 from "@img/a1/a1-10.png";

export const fourthLeftBg = `${window.jsStaticUrl}/assets/static/69784862632a50be5d797582f3590d78.png`;
export const fourthLeftWrapperBg = `${window.jsStaticUrl}/assets/static/ffaf28cdf2388777f9d89453c98a703a.png`;
export const fourthLeftImg = `${window.jsStaticUrl}/assets/static/69cdee46b97747716db794490ff1d959.png`;

export enum Tab {
  mobile = "mobild",
  pc = "web",
}

export const TabMap = {
  [Tab.mobile]: {
    icon: ai_mobile,
    type: Tab.mobile,
    describe: [
      "设计一个电商app，要求有商品详情页、搜索页面、购物车页、订单详情页",
      "生成一个音乐app，主题色为红色，深色模式，要求包括歌手介绍页、歌单页、排行榜页",
      "生成一个运动APP，主推功能为记录运动情况，主题色为绿色",
      "一个社交媒体APP ，有首页、探索、发现、我的四个tab页，主题色为绿色",
    ],
  },
  [Tab.pc]: {
    icon: ai_pc,
    type: Tab.pc,
    describe: [
      "设计一个类似 vogue 的网站，包含首页、摄影作品页、艺术家介绍页、Profile 页，黑白主题",
      "三体科普网站，介绍三体的影视化作品、作者、背景、核心概念，主题为暗黑模式，主题色为蓝色",
      "设计一款智能家居的官网，包含首页、家庭、场景、关于我们等模块",
      "设计一个自然博物馆的官网，设计醒目、简洁、有视觉冲击，颜色偏军绿色，包括首页、导览、展览、教育、活动、关于",
    ],
  },
} as Record<
  Tab,
  {
    icon: string;
    type: Tab;
    describe: string[];
  }
>;

export const jsonToUrlParam = (json: Record<string, any>) => {
  if (!isObject(json)) {
    throw new Error("参数类型错误");
  }
  return new URLSearchParams(json).toString();
};

export const A1IntroImgs = [
  {
    url: a1_img_1,
  },
  {
    url: a1_img_2,
  },
  {
    url: a1_img_3,
  },
  {
    url: a1_img_4,
  },
  {
    url: a1_img_5,
  },
  {
    url: a1_img_6,
  },
  {
    url: a1_img_7,
  },
  {
    url: a1_img_8,
  },
  {
    url: a1_img_9,
  },
  {
    url: a1_img_10,
  },
];
