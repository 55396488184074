import { useEffect, useMemo } from "react";
import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSPlugin } from "gsap/CSSPlugin";

import { isMobile } from "@utils/is";
import { goto } from "src/WithRouter";
// import { UPDATE_CONFIG } from "./constant";
import UpdateCard from "./components/UpdateCard";
import PcPrimaryTitle from "@components/PrimaryTitle/Pc";

import styles from "./index.module.less";
import Button from "@components/Button";
import { UPDATE_CONFIG } from "@pages/Home/constant";
import PrimaryButton from "@components/PrimaryButton";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger, CSSPlugin);
}

function UpdatedLog() {
  useEffect(() => {
    if (isMobile.phone) return;
    let updatedBtmTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".updated-wrap_btm",
        toggleActions: "play none none reset",
        scrub: true,
        start: "top",
      },
    });
    updatedBtmTl.to(".updated-wrap_btm", {
      yPercent: -7,
    });
  });

  const CURRENT_UPDATE_CONFIG = useMemo(() => {
    return UPDATE_CONFIG.slice(0, 3);
  }, []);

  return (
    <section
      className={cn({
        "width-full": true,
        [styles["updated-log"]]: true,
      })}>
      <div
        className={cn({
          "view-1": true,
          [styles["updated-wrap"]]: true,
        })}>
        <div className={styles["updated-wrap_top"]}>
          <div
            className={cn({
              "js-build-in": true,
              "build-in-scale-fade": true,
              "view-2": true,
              [styles.title]: true,
            })}>
            <PcPrimaryTitle
              className="margin-bottom-spacing-page"
              center={true}
              title="快速的产品更新迭代"
              des="我们每天都在进行功能和体验升级，这里可以第一时间看到即时设计的最新动态"
            />
            {!isMobile.phone && (
              <PrimaryButton
                style={{
                  width: "160px",
                  height: "4rem",
                }}
                onClick={() => goto("/update")}>
                <p>查看更新日志</p>
              </PrimaryButton>
            )}
          </div>
        </div>
        <div
          className={`updated-wrap_btm js-build-in build-in-scale-fade ${styles["updated-wrap_btm"]}`}>
          {CURRENT_UPDATE_CONFIG?.map((item: any) => {
            return (
              <div key={item.title}>
                <UpdateCard
                  title={item.title}
                  des={item.desc}
                  img={item.img}
                  date={item.date}
                  url={item.link}
                  color={item.color}
                  {...(item?.bgImg ? { bgImg: item.bgImg } : {})}
                  {...(item?.gif ? { gif: item.gif } : {})}
                  {...(item?.video ? { video: item.video } : {})}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default UpdatedLog;
