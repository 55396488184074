const userStateFn = (): I_User.State => {
  return {
    _id: "",
    isLoading: true,
    login:false,
  };
};

let userState: I_User.State = userStateFn();

/**
 * @description: 方便在非react组件内上下文中使用
 * @return {*}
 */
let userState_memorize: I_User.State = userStateFn();

const initUserState = (): I_User.State => {
  userState = userStateFn();
  return userState;
};

export { userState, initUserState, userState_memorize };
