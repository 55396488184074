import { AI_LOGO } from "@svg/home";
import { ExtraRouteObj } from ".";
import DefaultLoadApp from "../components/DefaultLoadApp";
import { AppNameMap, appList } from "../utils/garfish/app";
import ai_art from "@svg/ai_art.svg";
import ai_jsai from "@svg/ai_jsai.svg";
import ic_square from "@svg/ic_square.svg";
import ic_plugin from "@svg/ic_plugin.svg";
import ic_course from "@svg/ic_course.svg";
import ic_practice from "@svg/ic_practice.svg";
import ic_cooperation from "@svg/ic_cooperation.svg";
import ic_d2c from "@svg/ic_d2c.svg";
import ic_scene from "@svg/ic_scene.svg";
import ic_schoolSide from "@svg/ic_schoolSide.svg";
import ic_punchcard from "@svg/ic_punchcard.svg";
import ic_recommend from "@svg/ic_recommend.svg";
import ai_wegic from "@svg/ai_wegic.svg";
import { figma, sketch, xd } from "@pages/Home/constant";
import { Advantage, DesignCommunity } from "@components/Nav/Pc/extraContent";
import { dataWarehouse, getOption } from "@utils/operation-correlation";

export const routeItemAsBaseChildApp: Pick<
  ExtraRouteObj,
  "childAppName" | "appInfo" | "Component" | "extraContent"
> = {
  childAppName: AppNameMap.jsDesign,
  appInfo: appList.jsDesign,
  Component: DefaultLoadApp,
};

export const HomeRoutes: ExtraRouteObj[] = [
  {
    isExternal: true,
    name: "AI",
    icon: AI_LOGO,
    children: [
      {
        isExternal: true,
        name: "A1.art",
        path: "https://a1.art/?source=js&plan=mune",
        description: "一张图/一个词，免费生成高质量图像",
        icon: ai_art,
      },
      {
        path: "https://jsai.cc/ai",
        isExternal: true,
        name: "即时 AI",
        description: "根据文本描述,生成可编辑的UI设计稿",
        icon: ai_jsai,
      },
      {
        path: "https://wegic.ai/?source=jsmenu&plan=menu",
        isExternal: true,
        name: "Wegic",
        description: "90s 简单对话，完成精品网站设计&上线",
        icon: ai_wegic,
      },
    ],
  },
  {
    name: "设计社区",
    extraContent: DesignCommunity,
    children: [
      {
        ...routeItemAsBaseChildApp,
        name: "资源广场",
        icon: ic_square,
        description: "每周精选各类高质量涉及资源",
        path: "/community?category=explore",
      },
      {
        ...routeItemAsBaseChildApp,
        icon: ic_plugin,
        name: "插件广场",
        description: "开放插件体系,共营插件生态",
        path: "/community?category=plugin",
      },
      {
        ...routeItemAsBaseChildApp,
        name: "即时公开课",
        icon: ic_course,
        description: "探索创作者们精心策划的课程",
        path: "/courses",
      },
      {
        ...routeItemAsBaseChildApp,
        name: "每周临摹",
        description: "与众多设计师一起提升设计能力",
        path: "/practice",
        icon: ic_practice,
      },
      {
        ...routeItemAsBaseChildApp,
        icon: ic_punchcard,
        name: "打卡星球",
        description: "每期专题训练,养成设计技能",
        path: "/timeRecord",
      },
    ],
  },
  {
    name: "我们的优势",
    extraContent: Advantage,
    children: [
      {
        icon: ic_recommend,
        ...routeItemAsBaseChildApp,
        path: "/recommend",
        name: "向团队介绍",
        description: "如何全方位让团队提效",
      },
      {
        ...routeItemAsBaseChildApp,
        path: "/cooperation",
        icon: ic_cooperation,
        name: "合作伙伴",
        description: "与众多品牌和团队展开合作",
      },
      {
        ...routeItemAsBaseChildApp,
        path: "/d2c",
        icon: ic_d2c,
        name: "即时上线",
        description: "设计稿一键发布为网页",
      },
      {
        ...routeItemAsBaseChildApp,
        path: "/scene",
        name: "角色场景",
        icon: ic_scene,
        description: "团队角色效率一站式提升",
      },
      {
        ...routeItemAsBaseChildApp,
        path: "/SchoolSide",
        icon: ic_schoolSide,
        name: "校园计划",
        description: "与中国设计师共同成长",
      },
    ],
  },
  {
    name: "文件导入",
    children: [
      {
        ...routeItemAsBaseChildApp,
        icon: figma,
        name: "Figma文件导入",
        path: "/import?type=Figma",
        description: "全网首个原生支持Figma文件导入",
      },
      {
        ...routeItemAsBaseChildApp,
        icon: sketch,
        name: "Sketch文件导入",
        path: "/workspace",
        description: "在「即时设计」中打开Sketch设计",
      },
      {
        ...routeItemAsBaseChildApp,
        icon: xd,
        name: "AdobeXD文件导入",
        path: "/workspace",
        description: "在「即时设计」中打开XD设计",
      },
    ],
  },
  {
    ...routeItemAsBaseChildApp,
    name: "企业服务",
    path: "/privatization",
  },
  {
    ...routeItemAsBaseChildApp,
    name: "帮助中心",
    path: "/help",
    clickFunc: () => {
      dataWarehouse(getOption('button_click', { type: "top" }, 'behaviour'));
    }
  },
  {
    ...routeItemAsBaseChildApp,
    name: "最新功能",
    path: "/update",
  },
  {
    ...routeItemAsBaseChildApp,
    name: "下载",
    path: "/download",
  },
  {
    ...routeItemAsBaseChildApp,
    name: "定价",
    path: "/pricing",
  },
];
