import { ReactNode, useCallback, useEffect } from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import Routes, { ExtraRouteObj } from "@router/index";
import { UserProvider } from "./store/user/provider";
import {
  NotificationsProvider,
  useNotifications,
  useNotificationsDispatch,
} from "@components/NotificationsPanel/store";
import { PublishProvider } from "./store/publish/provider";
import { TopTipProvider } from "./store/top-tip/provider";
import { appElementAddClass } from "@utils/toggle-class/app";

import "./App.css";
import { mountStyle } from "./utils";

// route中的参数绑定到路由对应的组件内
const connectRouteComp = (routes: ExtraRouteObj[]): ExtraRouteObj[] => {
  return routes.map((item) => {
    const { Component: Com, children, index, ...others } = item;
    if (children && children.length) {
      return Com
        ? {
            ...others,
            element: <Com {...(others as any)} />,
            children: connectRouteComp(children),
          }
        : {
            ...item,
            children: connectRouteComp(children),
          };
    } else {
      if (Com) {
        return {
          ...others,
          element: <Com {...(others as any)} />,
        };
      } else {
        return item;
      }
    }
  });
};

const router = createBrowserRouter(connectRouteComp(Routes));

/**
 * @description: 添加全局监听等逻辑
 * @param {object}
 * @return {*}
 */
function DocumentListenerWrapper({ children }: { children: ReactNode }) {
  const { isShow } = useNotifications();
  const notificationsDispatch = useNotificationsDispatch();

  // 监听弹窗的显示隐藏
  const globalClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isShow) {
        notificationsDispatch({
          type: "toggleShow",
          payload: {
            isShow: false,
          },
        });
      }
    },
    [isShow]
  );

  return <div onClick={globalClick}>{children}</div>;
}

function LoadCssVariableWrapper({ children }: { children: ReactNode }) {
  useEffect(() => {
    appElementAddClass();
  }, []);

  return <div id="app">{children}</div>;
}

function StoreProvider({ children }: { children: ReactNode }) {
  return (
    <UserProvider>
      <TopTipProvider>
        <NotificationsProvider>
          <PublishProvider>{children}</PublishProvider>
        </NotificationsProvider>
      </TopTipProvider>
    </UserProvider>
  );
}

function App() {
  useEffect(() => {
    mountStyle();
  }, []);
  return (
    <LoadCssVariableWrapper>
      <StoreProvider>
        <DocumentListenerWrapper>
          <RouterProvider
            router={router}
            fallbackElement={<div>基座加载</div>}
          />
        </DocumentListenerWrapper>
      </StoreProvider>
    </LoadCssVariableWrapper>
  );
}

export default App;
