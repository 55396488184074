import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import cn from "classnames";

import { WORK_FLOW_CHART, WORK_FLOW_DATA } from "@pages/Home/constant";
import PcPrimaryTitle from "@components/PrimaryTitle/Pc";
import { isMobile } from "@utils/is";

import styles from "./index.module.less";
import MobilePrimaryTitle from "@components/PrimaryTitle/Mobile";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

function Workflow() {
  useEffect(() => {
    let workflowTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".workflow-card",
        toggleActions: "play pause resume reset",
        start: "top +=65%",
        end: "top +20%",
        once: true,
        scrub: true,
        markers: false,
      },
    });

    workflowTl
      .from(
        ".workflow-card_left_btm i",
        {
          width: 0,
          delay: 0.1,
        },
        "start"
      )
      .from(
        ".flow-item",
        {
          stagger: 0.2,
          opacity: 0,
        },
        "start"
      );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".workflow-card",
          toggleActions: "play none none reset",
          scrub: true,
          start: "top",
        },
      })
      .to(".workflow-card", {
        yPercent: -7,
      });
  }, []);

  return (
    <section
      className={cn({
        "width-full": true,
        [styles.workflow]: true,
      })}>
      <div
        className={cn({
          "view-1": true,
          [styles["workflow-wrap"]]: true,
        })}>
        <div
          className={cn({
            "view-2": true,
            [styles.title]: true,
          })}>
          {isMobile.phone ? (
            <MobilePrimaryTitle
              subTitle="现代设计工具的"
              title="全流程体验"
              des="即时设计贯穿产品创造全过程，用科学方法和强大功能助力产品上线。"
              center={true}
            />
          ) : (
            <PcPrimaryTitle
              subTitle="现代设计工具的"
              title="全流程体验"
              des="即时设计贯穿产品创造全过程，用科学方法和强大功能助力产品上线。"
              center={false}
            />
          )}
        </div>
        <div
          className={cn({
            "workflow-card": true,
            "width-full": true,
            "js-build-in": true,
            "build-in-scale-fade": true,
            [styles["workflow-card"]]: true,
          })}>
          <div
            className={cn({
              "workflow-card_left": true,
              [styles["workflow-card_left"]]: true,
            })}>
            <div className={styles["workflow-card_left_top"]}>
              <h3>贯穿产品创造全过程</h3>
              <p>
                为产研团队制定的角色化解决方案，从产品原型，页面交互，到设计师的完整设计创作，再到与开发的切图标注对接并交付上线，完整的产研工作流皆可通过即时设计一站式完成并实时协同。
              </p>
            </div>
            <div
              className={cn({
                "workflow-card_left_btm": true,
                [styles["workflow-card_left_btm"]]: true,
              })}>
              <i style={{ width: "100%" }}></i>
              <div className={styles["flow-wrap"]}>
                {WORK_FLOW_DATA.map((item, index) => {
                  return (
                    <div
                      className={cn({
                        "flow-item": true,
                        [styles["flow-item"]]: true,
                        [`flow-item-${index}`]: true,
                      })}
                      key={item.title}>
                      <p>{item.title}</p>
                      {item.items.map((t: any, i: any) => {
                        return <span key={t}>{t}</span>;
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className={styles["workflow-card_right"]}>
            <h3>
              使用即时设计
              <br />
              相比于传统工作流程
            </h3>
            <div className={styles["chart-wrap"]}>
              {WORK_FLOW_CHART.map((item, index) => {
                return (
                  <div className={styles["chart-item"]} key={item.title}>
                    <p>{item.title}</p>
                    <div className={styles["chart-line"]}>
                      <div></div>
                      <span>+{item.num}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Workflow;
