import * as React from "react";
import cn from "classnames";

import styles from "./index.module.less";

interface I_Props {
  title?: string;
  subTitle?: string;
  des?: string;
  center?: boolean;
  smaller?: boolean;
  className?: string;
}

export default function PcPrimaryTitle(props: I_Props) {
  const {
    title = "",
    subTitle,
    des = "",
    center = true,
    smaller = true,
    className = "",
  } = props;

  return (
    <div
      className={cn({
        [className]: className,
        [styles.title]: true,
        [styles["all-center"]]: center,
      })}>
      <h1
        className={cn({
          font: smaller ? 'var(--Heading-1)' : 'var(--Heading-0)',
          // 'Heading-0': !smaller,
          // 'Heading-1': smaller,
          [styles.marginBottomS20]: smaller,
        })}>
        {subTitle ? (
          <strong>
            {subTitle}
            <br />
          </strong>
        ) : null}
        {title
          .split("\n")
          .map(
            (v: string) => !!v && <React.Fragment key={v}>{v}</React.Fragment>
          )}
      </h1>
      <p>
        {des?.split("\n").map((v: string) => (
          <React.Fragment key={v}>
            {v}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  );
}
