import { useLayoutEffect } from "react";
import { showNativeLoading, hideNativeLoading } from "./util";

/**
 * react 桥接 native loading
 * doc: https://fyze31atzb.feishu.cn/docx/USQfdvW63o238Yxza22cwCPvnMf
 */
const NativeLoading = function () {
  useLayoutEffect(() => {
    showNativeLoading();
    return () => {
      hideNativeLoading();
    };
  }, []);
  return null;
};

export { NativeLoading };
