function reducer(state: I_Top_Tip.State, action: I_Top_Tip.Actions): I_Top_Tip.State {
  switch (action.type) {
    case "toggle-show": {
      const { payload } = action;
      return { ...state, ...payload };
    }

    default: {
      console.error(
        "userReducer 未实现的type ->> ",
        action,
      );

      return state;
    }
  }
}

export { reducer };
