import Button from "@components/Button";
import styles from "./index.module.less";
import { useNavigate } from "react-router-dom";
import DropDown from "@components/DropDown";
import { useUserStore } from "src/store/user";
import { LOGIN_OUT_TYPE, loginOut } from "src/request/login";
import LazyImg from "@components/LazyImg";
import { getAvatarUrl } from "@utils/index";

interface LoginInfoBtnProps {}

const userPanelRoute = [
  {
    name: "工作台",
    path: "/workspace",
  },
  {
    name: "账号设置",
    path: "/user",
  },
  {
    name: "个人主页",
    path: "/community?category=personHome",
  },
  {
    name: "退出登录",
    path: "/login",
  },
];

const LoginInfoBtn = (props: LoginInfoBtnProps) => {
  const n = useNavigate();
  const useData = useUserStore();
  // console.log("调试useData", useData);
  const { _id, userImage, nickname } = useData;
  return (
    <div className={styles.LoginInfoBtn}>
      <DropDown
        menu={{
          list: userPanelRoute,
          onclick(item) {
            let _path = "";
            switch (item.name) {
              case userPanelRoute[2].name:
                _path = `${item.path}&id=${_id}`;
                break;
              case userPanelRoute[3].name:
                loginOut({
                  type: LOGIN_OUT_TYPE.all,
                });
                break;
              default:
                _path = item.path;
                break;
            }
            n(_path || item.path);
          },
        }}
      >
        <Button
          styles={{
            minWidth: "120px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          onClick={() => n("/workspace")}
        >
          <span className={styles.avatar}>
            {userImage !== "Default" ? (
              <LazyImg src={getAvatarUrl(userImage!)} />
            ) : (
              nickname?.[0]
            )}
          </span>
          <span className={styles.text}>前往工作台</span>
        </Button>
      </DropDown>
    </div>
  );
};
export default LoginInfoBtn;
