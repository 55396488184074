import { useState } from "react";
import cn from "classnames";

import { TEAM_TAB_DATA } from "@pages/Home/constant";
import FeatureTab from "../FeatureTab";
import LazyVideo from "@components/LazyVideo";

import styles from "./index.module.less";
import Button from "@components/Button";
import { useNavigate } from "react-router-dom";

function FeatureTeam(props: any) {
  const [teamTab, setTeamTab] = useState(0);
  const n = useNavigate();
  const handleGoWorkSpace = () => n("/workspace");
  return (
    <div
      className={`${styles["features_card"]} ${styles["feature-team"]} js-build-in build-in-scale-fade`}
    >
      <div className={styles.left}>
        <div className={styles["left_mid"]}>
          <h2>团队协作</h2>
          <div className={styles["content-wrap"]}>
            <div
              className={cn({
                [styles["left_mid_content"]]: true,
              })}
            >
              <p className={styles["feature-team_subtitle"]}>
                {TEAM_TAB_DATA[teamTab].subTitle}
              </p>
              <p className={styles["feature-team_des"]}>
                {TEAM_TAB_DATA[teamTab].des}
              </p>
            </div>
          </div>
          <div className={styles["feature-tab-wrap"]}>
            {TEAM_TAB_DATA.map((item, i) => (
              <FeatureTab
                key={item.title}
                tabItem={item}
                isTabSelected={i === teamTab}
                handleClickTab={() => {
                  setTeamTab(i);
                }}
                className={styles["feature-tab-wrap_tab-item"]}
              />
            ))}
          </div>
        </div>
        <div className={styles["left_btm"]}>
          <Button
            onClick={handleGoWorkSpace}
            style={{
              width: "45%",
              padding: "10px 15px",
              boxShadow: "none",
              border: "2px solid #666667",
              borderRadius: "1000px",
              textAlign: "center",
              font: "500 1.286rem/1.92rem",
            }}
          >
            免费体验团队协作 <span>→</span>
          </Button>
        </div>
      </div>
      <div
        className={styles.right}
        style={{
          backgroundImage: `url(${window.jsStaticUrl}/assets/Resources/background/home-bg-2.png)`,
        }}
      >
        <div className={styles["card_cover-wrap"]}>
          {TEAM_TAB_DATA.map((item, i) => {
            return (
              <div
                className={cn({
                  [styles["card_cover-show"]]: teamTab === i,
                  [styles["card_cover"]]: true,
                })}
                key={item.title}
                onClick={() => setTeamTab((teamTab + 1) % 4)}
              >
                <LazyVideo src={item.src} autoPlay loop muted playsInline />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default FeatureTeam;
