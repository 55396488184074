import { checkIsNotReferrer } from "@utils/window/judge";

function getPcIpc() {
  if (checkIsNotReferrer()) return;

  const thisWindow: any = (window as any).pcParentApp ? window : window.parent;

  if (thisWindow) {
    return thisWindow.ipcRenderer || (window as any).ipcRenderer;
  }

  return null;
}

function isInPc() {
  return !!getPcIpc();
}

export { getPcIpc, isInPc };
