import { NotificationsHeaderList } from "../constant";
import { I_NotificationsContent } from "../type";

const TYPE_LIST = NotificationsHeaderList.map((item) => item.type);

const notificationsConfigObj = {
  relatedToMe: 1,
  showAlreadyRead: 0,
  "project-base": 1,
  "project-discuss": 1,
  "team-base": 1,
  "community-work": 0,
  "community-publish": 1,
  "community-plug": 1,
  "community-diffusion": 1,
  "official-base": 1,
} as const;

const notificationsState: I_NotificationsContent = {
  isShow: false,
  hoverElementPos: {} as DOMRect,
  type: TYPE_LIST[0],
  readAllMessageStatus: false,
  readCommunityStatus: false,
  readOfficialStatus: false,
  readProjectStatus: false,
  readTeamStatus: false,
  isShowDropFilter: false,
  socketChangeAddList: [],
  getNotificationsListFlag: false,
  configObj: notificationsConfigObj,
};

export { notificationsState, notificationsConfigObj };
