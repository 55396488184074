import { useEffect, useState, VideoHTMLAttributes } from "react";
import { useMemo, useRef, forwardRef, useImperativeHandle } from "react";

interface I_Props extends VideoHTMLAttributes<HTMLVideoElement> {
  className?: string;
  rootMargin?: number;
}

const LazyVideo = (props: I_Props) => {
  const { children, rootMargin = 200 } = props;
  const [isVisible, setIsVisible] = useState(false);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
            observer.disconnect();
          }
        });
      },
      {
        rootMargin: `${rootMargin}px`,
      }
    );

    if (videoRef.current) {
      observer.observe(videoRef.current);
    }

    return () => {
      if (observer && videoRef.current) {
        observer.unobserve(videoRef.current);
      }
    };
  }, []);

  const videoProps = useMemo(() => {
    const { rootMargin, src, poster, preload, ...leftProps } = props;

    return {
      src: isVisible ? src : '',
      poster: isVisible ? poster : '',
      preload: "none",
      ...leftProps,
    };
  }, [props, isVisible]);


  return  (
    <video ref={videoRef} {...videoProps}>
      {children}
    </video>
  ) ;
};

export default LazyVideo;
