function reducer(
  state: I_Publish.State,
  action: I_Publish.Actions
): I_Publish.State {
  switch (action.type) {
    case "setTotal": {
      const { payload } = action;
      return { ...payload, isUpdate: true };
    }

    default: {
      console.error("userReducer 未实现的type ->> ", action);

      return state;
    }
  }
}

export { reducer };
