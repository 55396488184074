import { useState, useEffect } from "react";
import { gsap } from "gsap";
import cn from "classnames";

import { checkIsInDingTalkPcOrFeiShuPc } from "@utils/judge-platform";
import { goto } from "src/WithRouter";
import { isMobile } from "@utils/is";
import { BaseIcon } from "@components/BaseIcon";
import { handleGetClockList, handleGetCopyList } from "src/request/copy";
import LazyImg from "@components/LazyImg";
import { dataWarehouse, getOption } from "@utils/operation-correlation";
import { RESOURCE_SVG_LIST } from "@pages/Home/constant";

import styles from "./index.module.less";
import { logger } from "@utils/logger";

function RightCard() {
  const [list, setList] = useState<any>([]);

  const gotoDetail = (url: string, id: string) => {
    const urls: string = (window as any).jsUrl + `${url}?id=${id}`;

    //添加埋点
    const isActivity = url == "/activity";
    dataWarehouse(
      getOption("activity_click", {
        location: "page",
        activityid: isActivity ? id : "",
        punchardid: isActivity ? "" : id,
      })
    );

    if (checkIsInDingTalkPcOrFeiShuPc()) {
      goto(`${url}?id=${id}`);
    } else {
      open(urls);
    }
  };

  useEffect(() => {
    Promise.all([handleGetCopyList({}), handleGetClockList({})])
      .then((res: any) => {
        const copys = res[0].fetchActivityList.map((item: any) => ({
          smallTitle: "每周临摹",
          name: item.name,
          description: item.description,
          viewNum: item.viewNum,
          activityWorkTotal: item.activityWorkTotal,
          id: item._id,
          imageSuffix: item.imageSuffix,
          imgUrl: `${(window as any).jsStaticUrl}/assets/activity/coverImage/${
            item._id
          }${item.imageSuffix}`,
          detailUrl: "/activity",
        }));
        const clocks = res[1].fetchPunchCardList.map((item: any) => ({
          smallTitle: "打卡星球",
          name: item.name,
          description: item.description,
          viewNum: item.viewNum,
          activityWorkTotal: item.peopleNo,
          id: item._id,
          imageSuffix: item.imageSuffix,
          imgUrl: `${(window as any).jsStaticUrl}/assets/punchCard/coverImage/${
            item._id
          }${item.imageSuffix}`,
          detailUrl: "/timeRecordDetail",
        }));
        setList([...clocks, ...copys]);
      })
      .catch((err) => {
        logger.log('error', `Promise.all([handleGetCopyList({}), handleGetClockList({})]) err ->> ${err}`)
      });
  }, []);

  useEffect(() => {
    const titleAnimation = gsap.timeline({ ease: "power2.out" });
    const descAnimation = gsap.timeline({ ease: "power2.out" });
    const numbersAnimation = gsap.timeline({ ease: "power2.out" });
    const img1Animation = gsap.timeline();
    const img2Animation = gsap.timeline();
    const img3Animation = gsap.timeline();

    const img1Top = isMobile.phone ? "0" : "3.357rem";
    const img2Top = isMobile.phone ? "-13px" : "2rem";
    const img3Top = isMobile.phone ? "-26px" : "0.643rem";

    titleAnimation
      .from(".design-right-title-animation", { duration: 0.25, opacity: 0.6 })
      .to(".design-right-title-animation", { opacity: 1 });
    descAnimation
      .from(".design-right .desc", { duration: 0.25, opacity: 0 })
      .to(".design-right .desc", { opacity: 0.5 });
    numbersAnimation
      .from(".design-right .numbers", { duration: 0.25, opacity: 0 })
      .to(".design-right .numbers", { opacity: 1 });

    img1Animation
      .set(".design-left .img1", {
        top: img1Top,
        opacity: 1,
        zIndex: 1,
        scale: 1,
      })
      .to(".design-left .img1", {
        top: img3Top,
        opacity: 0.6,
        scale: 0.86,
        duration: 0.25,
      });
    img2Animation
      .set(".design-left .img2", {
        top: img2Top,
        opacity: 0.8,
        zIndex: 3,
        scale: 0.93,
        backdropFilter: "blur(0)",
      })
      .to(".design-left .img2", {
        top: img1Top,
        opacity: 1,
        scale: 1,
        duration: 0.25,
      });
    img3Animation
      .set(".design-left .img3", {
        top: img3Top,
        opacity: 0.6,
        zIndex: 2,
        scale: 0.86,
        backdropFilter: "blur(3px)",
      })
      .to(".design-left .img3", {
        top: img2Top,
        opacity: 0.8,
        scale: 0.93,
        duration: 0.25,
      });
  }, [list]);

  const renderArticle = () => {
    const item = list[0] || {};

    return (
      <div className={styles["design-activity"]}>
        <div
          className={cn({
            "design-left": true,
            [styles["design-left"]]: true,
          })}>
          <div
            className={styles["img-wrap"]}
            onClick={() => {
              gotoDetail(item.detailUrl, item.id);
            }}>
            <LazyImg
              className={cn({
                img1: true,
                [styles.img]: true,
              })}
              src={list[1]?.imgUrl}
            />
            <LazyImg
              className={cn({
                img2: true,
                [styles.img]: true,
              })}
              src={list[0]?.imgUrl}
            />
            <LazyImg
              className={cn({
                img3: true,
                [styles.img]: true,
              })}
              src={list[2]?.imgUrl}
            />
          </div>
          <div className={styles.footer}>
            <div
              className={cn({
                change: true,
                [styles.change]: true,
              })}>
              <div
                className={styles["left-icon-wrap"]}
                onClick={() => {
                  changePage("left");
                }}>
                <BaseIcon
                  styleName={styles["left-icon"]}
                  iconSvg={RESOURCE_SVG_LIST.leftBtn}
                  size={8}
                />
              </div>
              <div
                className={styles["right-icon-wrap"]}
                onClick={() => {
                  changePage("right");
                }}>
                <BaseIcon
                  styleName={styles["right-icon"]}
                  iconSvg={RESOURCE_SVG_LIST.rightBtn}
                  size={8}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={cn({
            "design-right": true,
            [styles["design-right"]]: true,
          })}
          onClick={() => {
            gotoDetail(item.detailUrl, item.id);
          }}>
          <div className="design-right-title-animation">
            <div className={styles["small-title"]}>{item.smallTitle}</div>
            <div className={styles["big-title"]}>
              {item.name}
              {!isMobile.phone && (
                <LazyImg src={RESOURCE_SVG_LIST.arrowUpRight} alt="" />
              )}
            </div>
          </div>
          <div
            className={cn({
              desc: true,
              [styles["desc"]]: true,
            })}>
            {item.description}
          </div>
          <div className={styles["numbers"]}>
            <div
              className={cn({
                traffic: true,
                [styles.traffic]: true,
              })}>
              <BaseIcon
                iconSvg={RESOURCE_SVG_LIST.traffic}
                width={13}
                height={17}
              />
              <div className={styles.number}>{item.viewNum}</div>
            </div>
            <div className={styles.join}>
              <BaseIcon
                iconSvg={RESOURCE_SVG_LIST.join}
                width={15}
                height={17}
              />
              <div className={styles.number}>{item.activityWorkTotal}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const changePage = (direction: "left" | "right") => {
    setList((list: Array<any>) => {
      const _list = [...list];

      if (direction === "left") {
        _list.unshift(_list.pop());
      } else {
        _list.push(_list.shift());
      }

      return _list;
    });
  };

  return (
    <section className={styles["right-card"]}>
      <div className={styles["right-card-wrap"]}>
        <div className={styles.title}>设计活动</div>
        <div className={styles.subtitle}>和万千设计师一起成长</div>

        <div className={styles.content}>{renderArticle()}</div>
      </div>
    </section>
  );
}

export default RightCard;
