import { useNavigate } from "react-router-dom";
import { ComponentType, ReactNode, useEffect } from "react";
import { AppNameMap, AppOptions } from "../../utils/garfish/app";
import { ExtraRouteObj } from "../../router";
import useLoadApp from "./useLoadApp";
import { appElementRemoveClass } from "@utils/toggle-class/app";
import { unmountStyle } from "@utils/index";

interface DefaultLoadAppProps {
  children?: ReactNode;
  appInfo?: AppOptions;
  childAppName: string;
}

const DefaultLoadApp = (props: DefaultLoadAppProps & ExtraRouteObj) => {
  const { childAppName } = props;
  const n = useNavigate();
  // 初始化全局下发数据
  window.masterApp = {
    jumpPage: (path: string) => {
      // 手动卸载一下dom 不然会闪屏
      // @ts-ignore
      document.querySelector("#root").style.display = "none";
      n(path);
      // 老工程全局的操作太多，副作用回收的不干净，强制刷一下 后面慢慢拆了后再解决了
      childAppName === AppNameMap.jsDesign && window.location.reload();
    },
  };
  // 加载子应用
  const appInstance = useLoadApp({
    name: props.childAppName!,
    appInfo: props.appInfo!,
  });
  useEffect(() => {
    unmountStyle();
    appElementRemoveClass();
    console.log("props, appInstance", props, appInstance);
    appInstance && appInstance.mount();
  }, [appInstance]);
  return (
    <div
      style={{
        height: "100%",
        display: "flex",
      }}
    >
      <div
        style={{
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div id="subApp" style={{ width: "100%" }} />
      </div>
    </div>
  );
};
export default DefaultLoadApp as ComponentType;
