import { interfaces } from "garfish";
// import { getDomain } from "..";

export type AppOptions = Partial<Omit<interfaces.AppInfo, "name">>;

type AppNameMapType = typeof AppNameMap;

type AppList = Record<AppNameMapType[keyof AppNameMapType], AppOptions>;

export const AppNameMap = {
  jsDesign: "jsDesign",
  // childDemo: "childDemo",
} as const;

// 注册所有子应用
export const appList: AppList = {
  // [AppNameMap.childDemo]: {
  //   entry: `http://localhost:4003`,
  //   // entry: `${getDomain()}/child`,
  //   basename: "/child",
  //   domGetter: "#subApp",
  //   cache: false,
  // },
  [AppNameMap.jsDesign]: {
    entry: `${window.jsUrl || window.location.origin}/jsdesign`,
    domGetter: "#subApp",
    cache: false,
  },
};
