import dayjs from "dayjs";

import { objToString, stringToObj } from "@utils/parse";
import { isEmptyObj } from "@utils/is";
import { userState_memorize } from "src/store/user";

function setFirstEnter() {
    const isLogin = !!userState_memorize._id;

    if (isLogin) return;

    const firstEnter = stringToObj(window.localStorage.firstEnter) as any;

    if (firstEnter && !isEmptyObj(firstEnter)) {
        const { createTime } = firstEnter;
        const timeDiff = dayjs(new Date().toLocaleDateString()).diff(
            dayjs(new Date(createTime).toLocaleDateString()),
            "day"
        );

        if (Math.abs(timeDiff) != 0) {
            window.localStorage.firstEnter = objToString({
                firstEnterUrl: window.location.href,
                createTime: new Date(),
            });
        }
    } else {
        window.localStorage.firstEnter = objToString({
            firstEnterUrl: window.location.href,
            createTime: new Date(),
        });
    }
}

export {
    setFirstEnter
}