import { createContext, Dispatch, useContext } from "react";

const UserStore = createContext<Partial<I_User.State> | null>({
  isLoading: true,
  isLogin:'false',
  _id:"",
});

const UserDispatchStore = createContext<Dispatch<I_User.Actions> | null>(null);

function useUserStore() {
  return useContext(UserStore) as I_User.State;
}

function useUserDispatchStore() {
  return useContext(UserDispatchStore) as Dispatch<I_User.Actions>;
}

export { UserStore, UserDispatchStore, useUserStore, useUserDispatchStore };
