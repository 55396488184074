import dayjs from "dayjs";

import { getUrlParam } from "@utils/get";
import { objToString, stringToObj } from "@utils/parse";
import { userState_memorize } from "src/store/user";
import { isEmptyObj } from "@utils/is";

function checkIsFromExpand() {
    const pathName = location.pathname;
    // 如果是login 页面，不记录注册渠道 防止覆盖
    // if (!pathName.includes('login')) {
    //     const registerChannel = getRegisterChannelByPath(pathName);
    //     registerBuryPoint(registerChannel);
    // }

    const isLogin = !!userState_memorize._id;
    const resource = getUrlParam('source') || getUrlParam('y');
    const plan = getUrlParam('plan') || getUrlParam('j');
    const gclid = getUrlParam('gclid');
    if (isLogin || !(resource || plan || gclid)) return;

    const supportSources = ['baidu', '360', 'gg', 'sogou', 'BZ'];
    const targetSource = supportSources.find((source) => resource?.includes(source)) ?? '';
    const initialUrl = targetSource ? location.href : '';

    const markMap: Record<string | number, string> = {
        baidu: 'bd_vid',
        360: 'qhclickid',
        gg: 'gclid',
        sogou: 'sg_vid',
    };
    const mark = markMap[targetSource];

    const clickId = getUrlParam(mark) ?? '';

    window.localStorage.expandData = objToString({
        resource: resource || '',
        plan: plan || '',
        gclid: gclid || '',
        createTime: new Date(),
        initialUrl,
        clickId,
    });
}

function saveBaiduExpandData() {
    const bdvid = getUrlParam('bd_vid');
    const xl = getUrlParam('xl');

    if (bdvid) {
        window.localStorage.bdvid = objToString({
            url: window.location.href,
            createTime: new Date(),
            bdvid,
            ...(xl
                ? {
                    xl,
                }
                : {}),
        });
    }
}

function saveQiHuExpandData() {
    const qhcid = getUrlParam('qhclickid');
    const xl = getUrlParam('xl');
    const source = getUrlParam('source');

    if (qhcid) {
        window.localStorage.qhcid = objToString({
            createTime: new Date(),
            url: window.location.href,
            qhcid,
            ...(xl
                ? {
                    xl,
                }
                : {}),
            ...(source
                ? {
                    source,
                }
                : {}),
        });
    }
}

function saveZhiHuExpandData() {
    const source = getUrlParam('source');
    const xl = getUrlParam('xl');
    const sourceSet: string[] = ['zhxxl', 'zhxxl1', 'zhxxl2'];
    if (sourceSet.includes(source)) {
        window.localStorage.zhvid = objToString({
            url: window.location.href,
            createTime: new Date(),
            ...(source
                ? {
                    source,
                }
                : {}),
            ...(xl
                ? {
                    xl,
                }
                : {}),
        });
    }
}

function saveSouGouExpandData() {
    const sgvid = getUrlParam('sg_vid');
    const xl = getUrlParam('xl');

    if (sgvid) {
        window.localStorage.sgvid = objToString({
            url: window.location.href,
            createTime: new Date(),
            sgvid,
            ...(xl
                ? {
                    xl,
                }
                : {}),
        });
    }
}

function saveBiliBiliTrackId() {
    const isLogin = !!userState_memorize._id;

    if (isLogin) return;
    const trackId = getUrlParam('track_id');

    if (trackId) {
        window.localStorage.trackId = objToString({
            trackId,
            createTime: new Date(),
        });
    }

}

function checkIsRegisterSuccessFromExpand() {
    let isFromExpand = false;
    let curResource: any = '';
    let curPlan: any = '';
    let curGclid: any = '';
    const expandData: any = stringToObj(window.localStorage.expandData);

    if (expandData && !isEmptyObj(expandData)) {
        const { createTime, resource, plan, gclid } = expandData;
        const timeDiff: number = dayjs(new Date().toLocaleDateString()).diff(
            dayjs(new Date(createTime).toLocaleDateString()),
            'day'
        );

        if (timeDiff == 0) {
            isFromExpand = true;
            curResource = resource;
            curPlan = plan;
            curGclid = gclid;
        }
    }

    return {
        isFromExpand,
        resource: curResource,
        plan: curPlan,
        gclid: curGclid,
    };
}

export {
    checkIsFromExpand,
    saveBaiduExpandData,
    saveQiHuExpandData,
    saveZhiHuExpandData,
    saveSouGouExpandData,
    saveBiliBiliTrackId,
    checkIsRegisterSuccessFromExpand
}