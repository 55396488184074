import { post } from "@utils/https";

import { handleGetClassifyIdByType } from "@pages/Home/handle";

const handleGetSquareList = ({
  pageNum = 1,
  pageSize = 20,
  searchText = "",
  classify = "all",
  platform = "all",
  industry = "all",
  type = "all",
  copyright = "",
  isPublished = false,
  needThumbnail = false,
  sortType = "releasedDate",
}) => {
  const data = {
    pageNum,
    pageSize,
    ...(searchText !== "" ? { searchText } : {}),
    ...(copyright ? { copyright } : {}),
    ...(classify !== "" && classify != "all"
      ? { classifyId: handleGetClassifyIdByType(classify) }
      : {}),
    ...(platform != "all" ? { platform } : {}),
    ...(industry != "all" ? { industry } : {}),
    ...(type !== "" && type !== "all" ? { type } : {}),
    isPublished,
    needThumbnail,
    ...(sortType ? { sortType } : {}),
  };

  return post<any>({
    url: "/publish/list",
    data,
    config: {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    },
  });
};

const getPublishSelectedList = ({
  labels = "preference",
  searchText = "",
  pageSize = 20,
  pageNum = 1,
  platform = "",
  industry = "",
  type = "",
}) => {
  return post({
    url: "/publish/selected/list",
    data: {
      labels,
      searchText,
      pageSize,
      pageNum,
      platform,
      industry,
      type,
    },
  });
};

export { handleGetSquareList, getPublishSelectedList };
