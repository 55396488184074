import { useEffect, useState } from "react";
import styles from "./aiModal.module.less";
import ai_btn_icon from "@svg/ai_btn_icon.svg";
import LazyImg from "@components/LazyImg";
import Button, { ButtonType } from "@components/Button";
import hoverIcon from "@img/magic.png";
import { Tab, TabMap, jsonToUrlParam } from "./constant";

let i = 0;

const AiModal = () => {
  // tab状态
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.mobile);
  //   placeholder状态
  const [describeState, setDescribeState] = useState(
    TabMap[Tab.mobile].describe[0]
  );
  //   输入状态
  const [inputState, setInputState] = useState("");

  const switchTab = (item: Tab) => {
    if (item !== currentTab) {
      setCurrentTab(item);
      changePlaceHolder();
    }
  };

  const changePlaceHolder = () => {
    setDescribeState(TabMap[currentTab].describe[i]);
  };

  useEffect(() => {
    const timer = setInterval(() => {
      changePlaceHolder();
      if (i === TabMap[currentTab].describe.length - 1) {
        i = 0;
      } else {
        i++;
      }
    }, 4000);
    return () => {
      timer && clearInterval(timer);
    };
  }, [currentTab]);
  return (
    <div className={styles.aiModal}>
      <div className={styles.tab}>
        {Object.values(TabMap).map((item) => {
          return (
            <span
              className={currentTab === item.type ? styles.tabActive : ""}
              key={item.icon}
              onClick={() => switchTab(item.type)}
            >
              <LazyImg src={item.icon} alt="icon" />
            </span>
          );
        })}
      </div>
      <div className={styles.tabBody}>
        {Object.values(TabMap).map((item) => {
          if (item.type === currentTab) {
            return (
              <textarea
                key={item.type}
                className={styles.input}
                placeholder={describeState || item.describe[0]}
                onChange={(e) => setInputState(e.target.value)}
              />
            );
          }
          return "";
        })}
      </div>
      <div className={styles.operation}>
        <Button
          type={ButtonType.primary}
          className={styles.customButton}
          styles={{
            cursor: `url(${hoverIcon}) -4 -4, pointer`,
          }}
          onClick={() => {
            console.log("opennew page");
            window.open(`${window.jsUrl}/ai`);
          }}
        >
          <span className={styles.btnText}>
            <LazyImg src={ai_btn_icon} />
            开始创作
          </span>
        </Button>
      </div>
    </div>
  );
};

export default AiModal;
