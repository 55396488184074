import { isMobile } from "./is-mobile";

const isMac =
    window.navigator.platform === "Mac68K" ||
    window.navigator.platform === "MacPPC" ||
    window.navigator.platform === "Macintosh" ||
    window.navigator.platform === "MacIntel" ||
    isMobile.apple.tablet;

export {
    isMac
}
