import cn from "classnames";
import RegisterBtn from "../registerBtn";
import { isMobile } from "@utils/is";

import styles from "./index.module.less";

function FreeUse() {
  if (isMobile.phone) return "";
  return (
    <section
      className={cn({
        "js-build-in": true,
        "build-in-scale-fade": true,
        [styles["signUp-area"]]: true,
      })}
    >
      <h2>免费使用，即时设计</h2>
      <p>
        云端编辑的专业级 UI 设计工具，为中国设计师量身打造
        <br />
        Windows 也能用的「协作版 Sketch」
      </p>
      <div
        style={{
          width: "370px",
          margin: "0 auto",
        }}
      >
        <RegisterBtn isShowPrivateBtn />
      </div>
    </section>
  );
}

export default FreeUse;
