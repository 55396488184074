import Home from "@pages/Home";
import { RouteObject } from "react-router-dom";
import { AppOptions } from "@utils/garfish/app";
import { HomeRoutes, routeItemAsBaseChildApp } from "@router/home";

export type ExtraRouteObj = RouteObject & {
  // 作为基座页面时不需要写
  // 子应用加载信息
  appInfo?: AppOptions;
  // 路由名称 - 导航展示用的这个
  name: string;
  // 显示指定是否展示
  show?: boolean;
  // 用于传入自定义逻辑控制是否展示
  showCustom?: <T>(arg?: T) => boolean;
  // 是否是外部资源 - 新窗口打开
  isExternal?: boolean;
  // 路由描述 - 展示在页面下
  description?: string;
  children?: ExtraRouteObj[];
  // 子应用名称
  childAppName?: string;
  // 路由图标
  icon?: string;
  // 导航扩充内容
  extraContent?: () => JSX.Element;
  // 点击回调事件，用于埋点等业务场景，避免主站在跳转时根据path发埋点
  clickFunc?: () => void;
};

const JsBaseAppRoute = {
  name: "基座应用",
  ...routeItemAsBaseChildApp,
};

// 注册路由
const Routes: ExtraRouteObj[] = [
  {
    path: "/",
    name: "首页",
    Component: Home,
  },
  {
    path: "/home",
    name: "首页",
    show: false,
    Component: Home,
  },
  // 老工程
  {
    path: "/workspace",
    ...JsBaseAppRoute,
    name: "base",
  },
  // 非基座和其他子应用的路径目前都打到老工程里
  {
    path: "/f/:id",
    show: false,
    ...JsBaseAppRoute,
  },
  {
    path: "/*",
    show: false,
    ...JsBaseAppRoute,
  },
  ...HomeRoutes,
];

export default Routes;
