import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
// import { Draggable } from "gsap/Draggable";
import cn from "classnames";

import ResourceStatistic from "../../Resource/ResourceStatistic";
import FeatureToolCard from "./FeatureToolCard";
import { isMobile } from "@utils/is";
import { SAFE_DATA, StaticImgList, TOOL_TAB_DATA } from "@pages/Home/constant";
import LazyImg from "@components/LazyImg";

import feature_card3_1 from "@img/feature_card-3-1.png";

import styles from "./index.module.less";

if (typeof window !== "undefined") {
  // gsap.registerPlugin(ScrollTrigger, Draggable);
    gsap.registerPlugin(ScrollTrigger);
}

function FeatureTool(props: any) {
  useEffect(() => {
    // Draggable.create(".resource-card-wrap", {
    //   type: "x",
    //   edgeResistance: 0.65,
    //   bounds: ".feature-resource",
    //   lockAxis: true,
    //   inertia: true,
    // });

    if (isMobile.phone) {
      gsap.set(".features .resource-card-wrap", {
        opacity: 1,
        visibility: "visible",
      });
      return;
    }

    let resourceCardWrapTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".features .resource-card-wrap",
        toggleActions: "play pause resume reset",
        start: "top +=100%",
        end: "top +=70%",
        scrub: true,
      },
    });

    resourceCardWrapTl.fromTo(
      ".features .resource-card-wrap",
      {
        opacity: 0,
      },
      {
        opacity: 1,
        duration: 0.5,
        ease: "power2.out",
      }
    );

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".feature-safe .right",
          toggleActions: "play pause resume reset",
          start: "top +=90%",
          end: "top +=70%",
          scrub: true,
        },
      })
      .fromTo(
        ".feature-safe .right",
        {
          opacity: 0,
          y: 40,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power2.out",
        }
      );
  }, []);

  return (
    <div
      className={cn({
        [styles["features_cards-wrap_btm"]]: true,
      })}>
      <div className={styles["features_cards-wrap_btm_left"]}>
        <FeatureToolCard tabData={TOOL_TAB_DATA} title="工具优势" />
      </div>
      <div className={styles["features_cards-wrap_btm_right"]}>
        <div
          className={`${styles["features_card"]} feature-resource js-build-in build-in-scale-fade ${styles["feature-resource"]}`}>
          <div className={styles.top}>
            <div className={styles.left}>
              <h2>设计资源</h2>
              <p className={styles["design-resource"]}>
                数千款资源
                <br />
                即拿即用
              </p>
            </div>
            <ResourceStatistic className={styles["statistic-wrap"]} />
          </div>
          <div className={styles.btm}>
            <div
              className={cn({
                "resource-card-wrap": true,
                [styles["resource-card-wrap"]]: true,
              })}>
              {StaticImgList[0].map((item, index) => {
                return (
                  <div key={index}>
                    {
                      <div
                        onClick={() => {
                          open(item.url);
                        }}>
                        <LazyImg src={item.imgUrl} />
                      </div>
                    }
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div
          className={`${styles["features_card"]} feature-safe ${styles["feature-safe"]} js-build-in build-in-scale-fade`}>
          <div className={styles.left}>
            <div>
              <h2>数据安全</h2>
              <p>毫秒级存储，数据安全多重保障</p>
            </div>
            <ul>
              {SAFE_DATA.map((item) => (
                <li key={item.name}>
                  <div>
                    <LazyImg src={item.icon} />
                  </div>
                  {item.name}
                </li>
              ))}
            </ul>
          </div>
          <div className={styles.right}>
            <div className={styles["safe-card_cover"]}>
              <LazyImg src={feature_card3_1} className="width-full" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureTool;
