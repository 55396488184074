import { useEffect, useState } from "react";
import cn from "classnames";

import { dataWarehouse, getOption } from "@utils/operation-correlation";
import { useTopTipDispatchStore } from "src/store/top-tip";
import close from "../../../svg/close.svg";

import styles from "./index.module.less";
import LazyImg from "@components/LazyImg";

interface I_Props {
  position: string;
}

const PcTopTip = (props: I_Props) => {
  const { position } = props;
  const topTipDispatchStore = useTopTipDispatchStore();
  const [curShowTopTip, setCurShowTopTip] = useState(false);

  function handleClickClose(e: React.MouseEvent<HTMLSpanElement, MouseEvent>) {
    e.stopPropagation();
    topTipDispatchStore({
      type: "toggle-show",
      payload: {
        showTopTip: false,
      },
    });
    setCurShowTopTip(false);

    position === "topBanner" &&
      dataWarehouse(getOption("banner", { viewBanner: false })); //关闭 banner 的埋点
    sessionStorage.setItem("showTopTip", "false");
  }

  function topTipClick(e: any) {
    dataWarehouse(
      getOption("ad_space", {
        location: "banner",
        jumpurl: e?.url || "",
      })
    );
  }

  useEffect(() => {
    const isShow =
      sessionStorage.getItem("showTopTip") === "false" ? false : true;

    topTipDispatchStore({
      type: "toggle-show",
      payload: {
        showTopTip: isShow,
      },
    });
    setCurShowTopTip(isShow);
    dataWarehouse(getOption("banner", { viewBanner: true }));

    const topTip = document.getElementById("topTip") as any;
    topTip?.on &&
      topTip?.on("topBanner", () => {
        dataWarehouse(getOption("banner", { viewBanner: true }));
      });

    topTip?.on?.("__protoEvent__LINK", topTipClick);

    return () => {
      topTip?.off?.("__protoEvent__LINK", topTipClick);
    };
  }, []);

  if (!curShowTopTip) return null;

  return (
    <div
      id="topTipWrapper"
      className={cn({
        [styles.topTip]: true,
        // [cursorStyle["cursor-pointer"]]: !!props.onClick,
      })}>
      <div className={styles.left}>
        {
          <js-frame
            id="topTip"
            src={`${window.jsD2CBaseurl}/0EDBC0E3A764FBF32F047383`}></js-frame>
        }
      </div>
      <span
        onClick={handleClickClose}
        className={cn({
          [styles.closeBtn]: true,
          //   [cursorStyle["cursor-pointer"]]: true,
        })}>
        <LazyImg className={styles.closeIcon} src={close} alt="关闭" />
      </span>
    </div>
  );
};

export default PcTopTip;
