import * as React from "react";
import cn from "classnames";

import styles from "./index.module.less";

interface I_Props {
  title?: string;
  subTitle?: string;
  des?: string;
  center?: boolean;
  smaller?: boolean;
  className?: string;
}

export default function MobilePrimaryTitle(props: I_Props) {
  const { title = "", subTitle, des, center, className = "" } = props;

  return (
    <div
      className={cn({
        [className]: className,
        [styles.title]: true,
        [styles["all-center"]]: center,
      })}
    >
      <h1>
        {subTitle ? (
          <strong style={{ fontWeight: 400 }}>
            {subTitle}
            <br />
          </strong>
        ) : null}
        {title
          .split("\n")
          .map(
            (v: string) => !!v && <React.Fragment key={v}>{v}</React.Fragment>
          )}
      </h1>
      <p>
        {des?.split("\n").map((v: string) => (
          <React.Fragment key={v}>
            {v}
            <br />
          </React.Fragment>
        ))}
      </p>
    </div>
  );
}
