const LOGIN_OUT_MARK = "loginStatusMark";

enum LOGIN_ACTION {
  loginOut = "退出登录",
  toggleAccount = "切换账号",
  online = "在线中",
  enterpriseUserRemoved = "被企业移除",
}

export {
    LOGIN_OUT_MARK,
    LOGIN_ACTION
}