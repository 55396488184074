import { useState, useEffect } from "react";
import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import FeatureTab from "../../FeatureTab";
import LazyImg from "@components/LazyImg";

import styles from "./index.module.less";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

function FeatureToolCard(props: any) {
  const { tabData, title } = props;
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".feature-tool .top",
          toggleActions: "play pause resume reset",
          start: "top +=110%",
          end: "top +=85%",
          scrub: true,
        },
      })
      .fromTo(
        ".feature-tool .top",
        {
          opacity: 0,
          y: 140,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power2.out",
        }
      );
  }, []);

  return (
    <div
      className={`${styles["features_card"]} feature-tool js-build-in build-in-scale-fade ${styles["feature-tool_card"]}`}>
      <div className={styles.mid}>
        <h2>{title}</h2>
        <div className={styles['content-wrap']}>
          <div className={styles['mid_content']}>
            <p className={styles['feature-tool_subtitle']}>
              {tabData[selectedTab].subTitle}
            </p>
            <p className={styles['feature-tool_des']}>{tabData[selectedTab].des}</p>
          </div>
        </div>
        <div className={styles['feature-tab-wrap']}>
          {tabData.map((item: any, i: number) => (
            <FeatureTab
              key={item.title}
              tabItem={item}
              isTabSelected={i === selectedTab}
              handleClickTab={() => setSelectedTab(i)}
              className={styles['feature-tab-wrap_tab-item']}
            />
          ))}
        </div>
      </div>
      <div className={`${styles.top} top`}>
        <div>
          <div className="card_cover-wrap">
            {tabData.map((item: any, i: number) => {
              return (
                <div
                  className={cn({
                    [styles["card_cover-show"]]: selectedTab === i,
                    [styles['card_cover']]: true,
                  })}
                  key={item.title}
                  onClick={() =>
                    setSelectedTab((selectedTab + 1) % tabData.length)
                  }>
                  <LazyImg src={item.cover} className="width-full" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FeatureToolCard;
