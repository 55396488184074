import { merge } from "lodash-es"

class Logger {
    private _options: I_Logger.I_Logger_Options;

    constructor(options: Partial<I_Logger.I_Logger_Options> = {}) {
        const defaultOptions: I_Logger.I_Logger_Options = {
            showTimeStamp: true,
            levels: {
                log: 'color: black;',
                info: 'color: green;',
                warn: 'color: orange;',
                error: 'color: red;',
            }
        }
        this._options = merge(defaultOptions, options)
    }

    private formatMessage(level: keyof I_Logger.I_Logger_Options_Levels, message: I_Logger.I_Logger_Message) {
        const { showTimeStamp, levels } = this._options;

        const timeStamp = showTimeStamp ? `[${new Date().toISOString()}]` : '';
        const style = levels[level] || 'color: black;';
        const styledMessage = `%c${timeStamp}${level.toUpperCase()}%c: ${message}`

        // 返回格式化的消息和样式
        return {
            text: styledMessage,
            styles: [style, 'color: inherit;'] // 第二个样式用于继承上一个样式的颜色
        };
    }

    log(level: keyof I_Logger.I_Logger_Options_Levels, message: I_Logger.I_Logger_Message) {
        if (level in console) {

            const { text, styles } = this.formatMessage(level, message);

            // @ts-ignore
            console[level](text, ...styles);
        } else {
            // console.error()
        }

    }

    changeOptions(options: Partial<I_Logger.I_Logger_Options> = {}) {
        this._options = merge(this._options, options)
    }
}

const logger = new Logger()

export {
    logger
};