import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import cn from "classnames";

import { WHY_US_CARDS } from "@pages/Home/constant";
import LazyImg from "@components/LazyImg";

import styles from "./index.module.less";
import { BaseIcon } from "@components/BaseIcon";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

function WhyUs() {
  useEffect(() => {
    let designPrtl = gsap.timeline({
      scrollTrigger: {
        trigger: ".why-us",
        toggleActions: "play none none none",
        pin: true,
        pinSpacing: true,
        start: "+=20% center",
        end: "bottom center",
        markers: false,
        id: "why-us",
        scrub: 0,
      },
      duration: 1,
    });

    designPrtl
      .to(".why-us-card-1", {
        autoAlpha: 1,
        y: -120,
        duration: 1,
      })
      .to(".why-us-card-1", {
        scale: 0.9,
        autoAlpha: 0.3,
        duration: 0.2,
      })
      .to(".why-us-card-2", {
        autoAlpha: 1,
        y: -79,
        duration: 1,
      })
      .to(".why-us-card-2", {
        scale: 0.95,
        autoAlpha: 0.6,
        duration: 0.2,
      })
      .to(".why-us-card-3", {
        autoAlpha: 1,
        y: -35,
        duration: 1,
      })
      .to(".why-us-card-3", {
        autoAlpha: 1,
        duration: 0.2,
        scale: 1,
      });
  }, []);

  return (
    <div
      className={cn({
        "why-us": true,
        [styles["why-us"]]: true,
      })}
    >
      <div
        className={cn({
          "view-1": true,
          [styles["view-1"]]: true,
        })}
      >
        <div
          className={cn({
            "view-2": true,
            "js-build-in": true,
            [styles.right]: true,
          })}
        >
          {WHY_US_CARDS.map((item, index) => (
            <div
              className={cn({
                [styles["why-us_card"]]: true,
                ["why-us-card-" + (index + 1)]: true,
              })}
              key={item.title}
            >
              <div className={styles["card-title"]}>
                <div>
                  <div className={styles["card-icon"]}>
                    <BaseIcon iconSvg={item.icon} width={32} height={20} />
                  </div>
                  <p className={styles.subtitle}>{item.title}</p>
                </div>
                <h6
                  style={{
                    fontSize: "inherit",
                  }}
                >
                  {item.subTitle[0]}
                  <strong>{item.subTitle[1]}</strong>
                </h6>
              </div>
              <div className={styles.cover}>
                <div>
                  <LazyImg src={item.rightImg} className="width-full" />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default WhyUs;
