import { useReducer } from "react";

import { publishState } from "./state";
import { PublishStore, PublishDispatchStore } from "./context";
import { reducer } from "./reducer";

function PublishProvider({
  children,
}: {
  children: React.ReactNode | undefined;
}) {
  const [state, dispatch] = useReducer(reducer, publishState);

  return (
    <PublishStore.Provider value={state}>
      <PublishDispatchStore.Provider value={dispatch}>
        {children && children}
      </PublishDispatchStore.Provider>
    </PublishStore.Provider>
  );
}

export { PublishProvider };
