import { useReducer } from "react";

import { State } from "./state";
import { TopTipStore, TopTipDispatchStore } from "./context";
import { reducer } from "./reducer";

function TopTipProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(reducer, State);

  return (
    <TopTipStore.Provider value={state}>
      <TopTipDispatchStore.Provider value={dispatch}>
        {children}
      </TopTipDispatchStore.Provider>
    </TopTipStore.Provider>
  );
}

export { TopTipProvider };
