import axios, { AxiosError, AxiosInstance } from "axios";
import { pickBy } from "lodash-es";
import { I_ResponseData } from "./types";

export const config = {
  baseUrl: window.jsServerUrl || "http://test.geesdev.com/backend",
  timeout: 20000,
};

const instance: AxiosInstance = axios.create({
  baseURL: config.baseUrl,
  timeout: config.timeout,
  withCredentials: true, // 跨域请求时发送Cookie
});

instance.interceptors.request.use(
  function (config) {
    if (config.params) config.params = pickBy(config.params);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => {
    const { status, data } = response;

    const { msg, msg_cn, msg_unified } = data as I_ResponseData;
    const responseData = {
      ...data,
      msg_unified: msg_unified || msg_cn || msg,
    };

    switch (status) {
      case 200:
      case 201:
        return Promise.resolve(responseData);
    }
    return responseData;
  },
  ({ response, message }: AxiosError) => {
    if (!response) {
      return Promise.reject({
        code: -1,
        data: null,
        httpError: true,
        msg_unified: message || "获取数据出错",
        msg: message || "获取数据出错",
        msg_cn: message || "获取数据出错",
      });
    }

    const { status, data: responseData } = response;
    const { code, data, msg, msg_cn, msg_unified } =
      responseData as I_ResponseData;

    return Promise.reject({
      code: code ?? status ?? -1,
      data,
      httpError: true,
      msg_unified: msg_unified || msg_cn || msg || message || "获取数据出错",
      msg: msg || msg_unified || msg_cn || message || "获取数据出错",
      msg_cn: msg_cn || msg_unified || msg || message || "获取数据出错",
    });
  }
);

export default instance;
