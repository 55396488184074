import { useEffect, useState } from "react";

import { useTopTipStore } from "src/store/top-tip";
import { isMobile } from "@utils/is";

function getBarTop({
    afterNavBarAnimation = false, isShowTopTip = false
}) {
    let isShow = sessionStorage.getItem('showTopTip') === 'false' ? false : true;
    const topTipHeight =
        isShowTopTip && isShow
            ? isMobile.phone
                ? 60
                : 40
            : 0;
    const _height = afterNavBarAnimation ? 64 : 88;
    const navBarHeight = isMobile.phone ? 64 : _height;
    return {
        topTipHeight,
        navBarHeight
    }

}

/**
 * @description: 获取顶部栏高度
 * @return {*}
 */
function useListenBarTop(
    afterNavBarAnimation = false,
    dependencies: any[] = []
) {
    const [topTipHeight, setTopTipHeight] = useState(0);
    const [navBarHeight, setNavBarHeight] = useState(0);
    const { showTopTip } = useTopTipStore()

    const changeFn = () => {
        const { topTipHeight, navBarHeight } = getBarTop({
            afterNavBarAnimation,
            isShowTopTip: showTopTip
        });
        setTopTipHeight(topTipHeight);
        setNavBarHeight(navBarHeight);
    };

    useEffect(() => {
        changeFn();
    }, [...dependencies, showTopTip]);

    return { topTipHeight, navBarHeight };
}

export default useListenBarTop;