import { useMemo } from "react";
import cn from "classnames";

import {
  getIsAD,
  getResourceCoverUrl,
  handleGetResourceLibCompImgUrl,
} from "@pages/Home/handle";
import LazyImg from "@components/LazyImg";

import styles from "./index.module.less";

interface I_Props {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  resource: any;
  className?: string;
  forbiddenAD?: boolean;
  isRecommendResource?: boolean;
  libraryCoverImg?: string;
  isWidget?: boolean;
}

function ResourceCardContent(props: I_Props) {
  const {
    onClick,
    resource,
    className = "",
    forbiddenAD,
    isRecommendResource,
    isWidget = false,
  } = props;

  const { isPublished, isAD, isImageType, isFile } = useMemo(() => {
    return {
      isPublished: resource && !!resource.publishCode,
      isAD: getIsAD(resource),
      isImageType: resource?.imageType == "normal",
      isFile: resource?.type === "file",
    };
  }, [resource]);

  const isShowImg = useMemo(() => {
    return isImageType || isPublished || isRecommendResource || isFile;
  }, [isPublished, isAD, isImageType, isFile]);

  const { coverImgUrl, cover, componentArr } = useMemo(() => {
    return {
      coverImgUrl: resource.coverImgUrl,
      cover: resource.cover,
      componentArr: resource.componentArr || [],
    };
  }, [resource]);

  const imgUrl = useMemo(() => {
    return (
      coverImgUrl || cover || getResourceCoverUrl(resource, isRecommendResource)
    );
  }, [coverImgUrl, cover, resource]);

  if (forbiddenAD && isAD) return null;

  // 图片显示内容有三个部分 最大和其他两个内容
  // 简化操作，没有对图片的宽高进行再次判断
  const { biggestVal, otherVal } = useMemo(() => {
    if (isShowImg) return {} as any;
    let maxWH = -Infinity;
    let maxValIndex = -Infinity;
    componentArr?.forEach((item: any, index: number) => {
      const curMaxWH = Math.max(item.elementW, item.elementH);
      if (curMaxWH > maxWH) {
        maxWH = curMaxWH;
        maxValIndex = index;
      }
    });
    if (maxValIndex < 0) return {};
    const curBiggestVal = componentArr[maxValIndex];
    const curOtherVal =
      componentArr.filter((_: any, index: number) => index != maxValIndex) ||
      [];

    curBiggestVal.imgUrl = handleGetResourceLibCompImgUrl(
      curBiggestVal.shortId,
      curBiggestVal.coverHash
    );

    curOtherVal.map((item: any) => {
      item.imgUrl = handleGetResourceLibCompImgUrl(
        item.shortId,
        item.coverHash
      );
    });

    return {
      biggestVal: curBiggestVal,
      otherVal: curOtherVal,
    };
  }, [isShowImg, componentArr]);

  return (
    <div
      className={cn({
        [`${className}`]: className,
        [styles["card-content-notWidget"]]: true,
        [styles["card-content-widget"]]: isWidget,
      })}
      onClick={onClick}
      data-type="button">
      {isShowImg ? (
        imgUrl && <LazyImg src={imgUrl} className="cover-img" />
      ) : (
        <div className={styles["content-wrap"]}>
          <div className={styles.libResourceBox}>
            {biggestVal && (
              <div
                className={cn({
                  [styles.thumbBox]: true,
                  [styles.firstThumb]: true,
                })}>
                <LazyImg
                  className={styles.thumbnailImg}
                  src={biggestVal.imgUrl}
                  width={biggestVal.imgWidth}
                  height={biggestVal.imgHeight}
                />
              </div>
            )}

            {otherVal && otherVal?.length >= 2 && (
              <div className={styles.otherThumbBox}>
                <div
                  className={cn({
                    [styles.thumbBox]: true,
                    [styles.secondThumb]: true,
                  })}>
                  <LazyImg src={otherVal[0].imgUrl} />
                </div>
                <div
                  className={cn({
                    [styles.thumbBox]: true,
                    [styles.thirdThumb]: true,
                  })}>
                  <LazyImg src={otherVal[1].imgUrl} />
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default ResourceCardContent;
