import { isMobile } from "@utils/is";;

function preventDefault(e: any) {
  try {
    if (e?.stopPropagation) e.stopPropagation();
    if (e?.preventDefault && !isMobile.phone) e.preventDefault();
  } catch (error) {
    console.log(error);
  }
}

export { preventDefault };
