import { get } from "@utils/https";

function getUserInfo() {
  return get({
    url: "/api/user/v1",
    // TODO: 暂时注释，会有问题
    // config: {
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
    // },
  });
}

export { getUserInfo };
