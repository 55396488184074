import React, { CSSProperties, HTMLAttributes } from "react";
import styles from "./index.module.less";

export enum ButtonType {
  default = "default",
  primary = "primary",
}
export interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  text?: string;
  type?: ButtonType;
  onClick?: (e: React.MouseEvent) => void;
  styles?: CSSProperties;
}

const Button = (props: ButtonProps) => {
  const {
    text,
    type = ButtonType.default,
    children,
    className,
    ...other
  } = props;
  return (
    <div
      style={props.styles || {}}
      className={`${styles.button} ${styles[type]} ${className || ""}`}
      {...other}
    >
      {(text || children || "按钮") as React.ReactNode}
    </div>
  );
};

export default Button;
