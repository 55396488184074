import cn from "classnames";

import LazyImg from "@components/LazyImg";

import styles from "./index.module.less";

interface I_Props {
  tabItem: any;
  isTabSelected: boolean;
  handleClickTab: any;
  className?: string;
}

function FeatureTab(props: I_Props) {
  const { tabItem, isTabSelected, handleClickTab, className } = props;
  return (
    <div
      className={cn({
        [styles["team-tab"]]: true,
        [styles["team-tab-active"]]: isTabSelected,
        "feature-tab-wrap_tab-item": true,
        [`${className}`]: className,
      })}
      onClick={handleClickTab}>
      <div className={styles["feature-tab-wrapper"]}>
        <div className={styles["feature-tab-icon"]}>
          <LazyImg
            src={tabItem.icon}
            width={30}
            height={30}
            className="width-full height-full"
          />
        </div>
        <span className={styles["tab-text"]}>{tabItem.title}</span>
      </div>
    </div>
  );
}

export default FeatureTab;
