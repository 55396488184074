import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSPlugin } from "gsap/CSSPlugin";
import cn from "classnames";

import FeatureTool from "./FeatureTool";
import FeatureTeam from "./FeatureTeam";
import { isMobile } from "@utils/is";
import PcPrimaryTitle from "@components/PrimaryTitle/Pc";
import MobilePrimaryTitle from "@components/PrimaryTitle/Mobile";

import styles from "./index.module.less";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger, CSSPlugin);
}

function Feature({ className }: { className?: string }) {
  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".features",
          toggleActions: "play none none reset",
          scrub: true,
          start: "80%",
        },
      })
      .to(".features", {
        yPercent: -7,
      });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".features .title",
          toggleActions: "play none none reset",
          scrub: true,
          start: "top",
        },
      })
      .to(".features .title", {
        yPercent: -15,
      });

    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".features .card_cover-wrap",
          toggleActions: "play pause resume reset",
          start: "top +=100%",
          end: "top +=60%",
          scrub: true,
        },
      })
      .fromTo(
        ".features .card_cover-wrap",
        {
          opacity: 0,
          y: 140,
        },
        {
          opacity: 1,
          y: 0,
          duration: 0.5,
          ease: "power2.out",
        }
      );
  }, []);

  return (
    <section
      className={cn({
        'features': true,
        [styles.features]: true,
      })}>
      <div className="title js-build-in build-in-scale-fade view-2">
        {isMobile.phone ? (
          <MobilePrimaryTitle
            subTitle="为什么"
            title="选即时设计"
            des="打造服务产研的次世代专业级设计工具，更快实现你和团队的想法。"
            center={true}
            className={styles.title}
          />
        ) : (
          <PcPrimaryTitle
            subTitle="为什么"
            title="选即时设计"
            des="打造服务产研的次世代专业级设计工具，更快实现你和团队的想法。"
            center={true}
            className={styles.title}
          />
        )}
      </div>
      <div className={styles['features_cards-wrap']}>
        <FeatureTeam  />
        <FeatureTool  />
      </div>
    </section>
  );
}

export default Feature;
