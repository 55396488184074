import { useEffect } from "react";
// import styles from "./index.module.less";
import { footerData } from "./constant";
import { useNavigate } from "react-router-dom";
import { FooterD2c } from "@pages/Home/constant";
import { isMobile } from "@utils/is";

interface FooterProps {}

const Footer = (props: FooterProps) => {
  const n = useNavigate();
  useEffect(() => {
    setTimeout(() => {
      const js_footer: any = document.getElementById("js_footer");
      footerData.forEach((item) => {
        js_footer?.on?.(item.title, () => {
          if (item.path) {
            n(item.path);
          } else {
            window.location.href = "" + item.href;
          }
        });
      });
    }, 100);
  }, []);
  return (
    <div className={"commWrap"}>
      <js-frame
        style={{ width: "100%", height: "100%" }}
        id={"js_footer"}
        src={isMobile.phone ? FooterD2c.mobile : FooterD2c.pc}
      />
    </div>
  );
};
export default Footer;
