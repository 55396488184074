import { ReactNode, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { isMobile } from "@utils/is";
import { WithRouter } from "src/WithRouter";
import PcTopTip from "@components/TopTip/Pc";
import PcNav from "@components/Nav/Pc";
import {
  checkIsFromExpand,
  getIsLoadPage,
  saveBiliBiliTrackId,
  saveOCPCData,
  setFirstEnter,
} from "@utils/operation-correlation";
import useListenBarTop from "@utils/hooks/useListenBarTop";
import { lazyAdapter } from "@components/LazyLoad";

const MobileTopTip: any = lazyAdapter(
  () => import("@components/TopTip/Mobile")
);
const MobileNav: any = lazyAdapter(() => import("@components/Nav/Mobile"));

import styles from "./index.module.less";

interface I_Props {
  children: ReactNode;
}

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

function PageContainer(props: I_Props) {
  const isPhone = isMobile.phone;
  const { topTipHeight, navBarHeight } = useListenBarTop();
  const { pathname } = window.location;

  useEffect(() => {
    checkIsFromExpand();
    saveOCPCData();
    saveBiliBiliTrackId();
    getIsLoadPage();
    setFirstEnter();
  }, []);

  useEffect(() => {
    ScrollTrigger.clearScrollMemory();
    ScrollTrigger.clearMatchMedia();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isMobile.phone) return;
    if (pathname === "/home") {
      gsap.to(".global-nav", {
        scrollTrigger: {
          trigger: ".features",
          start: "top",
          toggleActions: "play none none reset",
        },
        duration: 0.01,
        height: 64,
        boxShadow: "0 0 0 1px #ececec",
      });
    }
  }, [pathname]);

  return (
    <div className={"pageContainer"}>
      <WithRouter />
      {isPhone ? (
        <MobileTopTip position="topBanner" />
      ) : (
        <PcTopTip position="topBanner" />
      )}
      <div
        className={styles.content}
        style={{ marginTop: topTipHeight + "px" }}>
        {isPhone ? <MobileNav /> : <PcNav />}
        <div
          style={{
            marginTop: navBarHeight + "px",
          }}>
          {props.children}
        </div>
      </div>
    </div>
  );
}

export default PageContainer;
