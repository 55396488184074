function stringToObj(str: string): Object {
    try {
        return str ? JSON.parse(str) : str;
    } catch (err) {
        console.error('stringToObj ->>', err);
        return {}
    }
}

export {
    stringToObj
}