import InlineSVG from "svg-inline-react";
import styles from "./index.module.less";
import { icon_back } from "@svg/home";
import { useEffect, useState } from "react";

const TOP_VAL = 88;

const getTopStatus = () => {
  const viewDom = document.querySelector("main");
  const main = document.querySelector("#firstScreen");
  const { y } = viewDom?.getBoundingClientRect() || { y: TOP_VAL };
  if (y >= TOP_VAL && main?.scrollTop === 0) {
    return true;
  }
  return false;
};

const BackTop = () => {
  const [isTop, setIsTop] = useState(true);

  useEffect(() => {
    const handleCb = () => {
      const res = getTopStatus();
      res !== isTop && setIsTop(res);
    };

    window.addEventListener("scroll", handleCb, true);

    return () => {
      window.removeEventListener("scroll", handleCb);
    };
  }, []);

  if (isTop) {
    return "";
  }
  return (
    <div
      className={styles.backTop}
      onClick={() => {
        window.scrollTo(0, 0);
        setIsTop(true);
      }}>
      <InlineSVG src={icon_back} />
    </div>
  );
};

export default BackTop;
