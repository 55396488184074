import { useState, useEffect } from "react";
import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSPlugin } from "gsap/CSSPlugin";

import LazyImg from "@components/LazyImg";

import highlight_ic_2 from "../../img/highlight-icon-2.png";
import highlight_card_1_1 from "../../img/highlight-card-1-1.png";
import highlight_card_1_2 from "../../img/highlight-card-1-2.png";
import highlight_card_2_1 from "../../img/highlight-card-2_1.png";
import highlight_card_2_2 from "../../img/highlight-card-2_2.png";
import highlight_card_2_3 from "../../img/highlight-card-2_3.png";
import highlight_card_2_4 from "../../img/highlight-card-2_4.png";
import highlight_card_2_5 from "../../img/highlight-card-2_5.png";
import highlight_card_3 from "../../img/highlight-card-3.png";
// import * as cursorStyle from "app/style/cursor/index.css";

import styles from "./index.module.less";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger, CSSPlugin);
}

const HIGHLIGHT_CARDS = [
  highlight_card_2_1,
  highlight_card_2_2,
  highlight_card_2_3,
  highlight_card_2_4,
  highlight_card_2_5,
];

function HighLight(props: { className?: string }) {
  const { className = "" } = props;
  const [colorTab, setColorTab] = useState(0);

  useEffect(() => {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".highlight",
          toggleActions: "play none none reset",
          scrub: true,
          start: "top",
        },
      })
      .to(".highlight", {
        yPercent: -7,
      });
    gsap
      .timeline({
        scrollTrigger: {
          trigger: ".highlight-card-mobile",
          start: "top center",
          end: "bottom center",
        },
      })
      .from(".highlight-cover-ipad", {
        yPercent: 10,
        xPercent: -10,
        autoAlpha: 0,
        scale: 0.9,
        duration: 0.5,
      });
  });

  return (
    <section
      className={cn({
        "width-full": true,
        highlight: true,
        [`${className}`]: true,
        [styles.highlight]: true,
      })}
    >
      <div
        className={cn({
          "view-1": true,
          "js-build-in": true,
          "build-in-scale-fade": true,
          [styles["highlight-wrap"]]: true,
        })}
      >
        <div
          className={cn({
            [styles["highlight-card"]]: true,
            [styles["highlight-card-color"]]: true,
            // @ts-ignore
            [styles[`highlight-card-${colorTab + 1}`]]: true,
          })}
        >
          <div className={styles.top}>
            <LazyImg src={highlight_ic_2} width={64} height={64} />

            {/* @ts-ignore */}
            <p className={styles[`ui-color-p-${colorTab + 1}`]}>
              5 种高亮色搭配，
              <br />
              <strong>在工作中找到个性化乐趣</strong>
            </p>
            <div className={styles["color-tab-wrap"]}>
              {Array.from({ length: 5 }).map((item, index) => {
                return (
                  <div
                    onClick={() => setColorTab(index)}
                    key={index}
                    className={cn({
                      [styles["color-tab-item"]]: true,
                      // @ts-ignore
                      [styles[`color-tab-item-active-${index + 1}`]]:
                        colorTab === index,
                    })}
                  />
                );
              })}
            </div>
          </div>
          <div
            className={cn({
              [styles["highlight-cover"]]: true,
              // @ts-ignore
              [styles[`highlight-cover-${colorTab + 1}`]]: true,
            })}
            style={{
              backgroundImage: `${window.jsStaticUrl}/assets/img/61511e377a34757006c91659.png`,
            }}
          >
            {HIGHLIGHT_CARDS.map((item, index) => {
              return (
                <div
                  className={cn({
                    // [cursorStyle["cursor-pointer"]]: true,
                    [styles["highlight-cover_img-show"]]: colorTab === index,
                    [styles["highlight-cover_img"]]: true,
                  })}
                  key={index}
                  onClick={() => setColorTab((colorTab + 1) % 5)}
                >
                  <LazyImg src={item} className="width-full" />
                </div>
              );
            })}
          </div>
        </div>

        <div
          className={cn({
            "js-build-in": true,
            "build-in-scale-fade": true,
            [styles["highlight-wrap_btm"]]: true,
          })}
        >
          <div
            className={cn({
              "highlight-card-mobile": true,
              [styles["highlight-card"]]: true,
              [styles["highlight-card-mobile"]]: true,
            })}
          >
            <div className={styles["top"]}>
              <p>
                跨平台使用，
                <strong>随时随地发挥创意</strong>
              </p>
            </div>
            <div className={styles["highlight-cover"]}>
              <div>
                <LazyImg src={highlight_card_1_1} className="width-full" />
              </div>
              <div className={"highlight-cover-ipad"}>
                <LazyImg src={highlight_card_1_2} className="width-full" />
              </div>
            </div>
          </div>
          <div
            className={cn({
              [styles["highlight-card"]]: true,
              [styles["highlight-card-dark"]]: true,
            })}
            style={{
              backgroundImage: `url(${window.jsStaticUrl}/assets/img/61511e377a34757006c91659.png)`,
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className={styles.top}>
              <p>
                Light/Dark 模式，<strong>只为更好的用户体验</strong>
              </p>
            </div>
            <div className={styles["highlight-cover"]}>
              <div>
                <LazyImg src={highlight_card_3} className="width-full" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default HighLight;
