import { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import cn from "classnames";

import ResourceBtn from "../ResourceBtn";
import { checkIsInDingTalkPcOrFeiShuPc } from "@utils/judge-platform";
import { goto } from "src/WithRouter";
import { isMobile } from "@utils/is";
import { RESOURCE_SVG_LIST } from "@pages/Home/constant";
import { BaseIcon } from "@components/BaseIcon";
import { handleGetPluginList } from "src/request/plugin";
import { gotoCommunityPage } from "@pages/Home/handle";
import LazyImg from "@components/LazyImg";
import { logger } from "@utils/logger";

import leftBg from "../../../img/left-bg.png";
import leftBgPhone from "../../../img/left-bg-phone.png";

import styles from "./index.module.less";

const pageSize = isMobile.phone ? 4 : 6;
const scroll = gsap.timeline({
  ease: "power2.out",
});

function LeftCard() {
  const scrollRef = useRef<HTMLDivElement>();
  const [contentWidth, setContentWidth] = useState(0);
  const [pageNum, setPageNum] = useState(1);
  const [direction, setDirection] = useState("");
  const [pluginList, setPluginList] = useState<any>({} as any);

  useEffect(() => {
    handleGetPluginList({
      pageNum,
      size: pageSize,
    })
      .then((res) => {
        setPluginList((res as any).data);
      })
      .catch((err) => {
        logger.log("error", `handleGetPluginList err ->> ${err}`);
      });
  }, [pageNum]);

  useEffect(() => {
    if (direction) {
      const nextX = direction === "left" ? 0 : 2 * -contentWidth;
      scroll
        .set(".left-card-wrap .content-scroll", { x: -contentWidth })
        .to(".left-card-wrap .content-scroll", { x: nextX, duration: 0.25 })
        .set(".left-card-wrap .content-scroll", { x: -contentWidth });
    }
  }, [pluginList, contentWidth]);

  useEffect(() => {
    setTimeout(() => {
      setContentWidth(scrollRef.current?.clientWidth as number);
    }, 1000);

    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const resize = () => {
    setContentWidth(scrollRef.current?.clientWidth as number);
  };

  const renderArticle = (pluginList: any) => {
    const childrenArr = (pluginList?.list || []).map((item: any) => {
      const { name, introduce, icon, _id, iconBgColor } = item || {};

      return (
        <div
          className={styles["article"]}
          key={_id}
          onClick={() => {
            gotoDetail(_id);
          }}>
          <div
            className={styles["article-icon"]}
            style={{ background: iconBgColor }}>
            <LazyImg
              src={
                `${(window as any).jsStaticUrl}/assets/plug/icon/${_id}` + icon
              }
            />
          </div>
          <div className={styles["article-info"]}>
            <div className={styles["article-name"]}>{name}</div>
            <div className={styles["article-introduce"]}>{introduce}</div>
          </div>
        </div>
      );
    });

    return childrenArr;
  };

  const renderContent = () => {
    const children = Array.from({ length: 3 }).map((item, index) => {
      return (
        <div className={styles.content} key={index}>
          {renderArticle(pluginList)}
        </div>
      );
    });

    return children;
  };

  const dealBorder = (allPage: number, pageNum: number) => {
    let _pageNum = pageNum;

    if (_pageNum <= 0) {
      _pageNum = allPage;
    } else if (_pageNum > allPage) {
      _pageNum = 1;
    }

    return _pageNum;
  };

  const changePage = (direction: "left" | "right") => {
    setDirection(direction);
    setPageNum((pageNum) => {
      let _pageNum = pageNum;
      const allPage = pluginList?.page?.allPage;
      if (!allPage) return _pageNum;

      if (direction === "left") {
        _pageNum -= 1;
      } else {
        _pageNum += 1;
      }

      _pageNum = dealBorder(allPage, _pageNum);

      return _pageNum;
    });
  };

  const gotoDetail = (id: string) => {
    const url: string = (window as any).jsUrl + `/pluginDetail?id=${id}`;

    if (checkIsInDingTalkPcOrFeiShuPc()) {
      goto(`/pluginDetail?id=${id}`);
    } else {
      open(url);
    }
  };

  return (
    <div className={styles["left-card"]}>
      <div
        className={cn({
          "left-card-wrap": true,
          [styles["left-card-wrap"]]: true,
        })}>
        <LazyImg
          className={styles["left-bg"]}
          src={isMobile.phone ? leftBgPhone : leftBg}
        />
        <div className={styles["title"]}>插件广场</div>
        <div className={styles["sub-title"]}>开放的插件生态</div>

        <div className={styles["content-scroll-wrap"]}>
          <div
            className={cn({
              "content-scroll": true,
              [styles["content-scroll"]]: true,
            })}
            ref={scrollRef as any}>
            {renderContent()}
          </div>
        </div>

        <div className={styles["footer"]}>
          <ResourceBtn
            className={styles.resourceBtn}
            title={"插件广场"}
            onClick={() => {
              gotoCommunityPage("plugin", {}, true);
            }}
          />

          <div className={styles["change"]}>
            <div
              className={styles["left-icon-wrap"]}
              onClick={() => {
                changePage("left");
              }}>
              <BaseIcon
                styleName={styles["left-icon"]}
                iconSvg={RESOURCE_SVG_LIST.leftBtn}
                size={8}
              />
            </div>
            <div
              className={styles["right-icon-wrap"]}
              onClick={() => {
                changePage("right");
              }}>
              <BaseIcon
                styleName={styles["right-icon"]}
                iconSvg={RESOURCE_SVG_LIST.rightBtn}
                size={8}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftCard;
