import { useState, useEffect, useMemo, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import cn from "classnames";

import ResourceStatistic from "./ResourceStatistic";
import LeftCard from "./LeftCard";
import RightCard from "./RightCard";
import ResourceBtn from "./ResourceBtn";
import { isEmptyObj, isMobile } from "@utils/is";
import { getIsAD, gotoCommunityPage } from "../../handle";
import {
  getPublishSelectedList,
  handleGetSquareList,
} from "src/request/square";
import PcPrimaryTitle from "@components/PrimaryTitle/Pc";
import ResourceCardContent from "./ResourceCardContent";
import TabFilter from "./TabFilter";
import { RESOURCE_TABS } from "@pages/Home/constant";
import LazyImg from "@components/LazyImg";
import { logger } from "@utils/logger";
import { useLazyLoad } from "@utils/hooks";

import twoCardBg from "../../img/two-card-bg.png";
import resourceBigBg from "../../img/resource-big-bg.svg";
import point from "../../img/point.svg";

import styles from "./index.module.less";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const initGsap = () => {
  let resourceTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".resource",
      toggleActions: "play pause resume reset",
      start: isMobile.phone ? "-=10%" : "-=50%",
      end: isMobile.phone ? "+=100%" : "+=200%",
      scrub: 0.4,
      markers: false,
    },
  });

  resourceTl.to(".resource-scroll-1", { x: isMobile.phone ? -240 : -480 }, 0);
};

const gotoOneWork = (id: string) => {
  const url: any = (window as any).jsUrl + `/resourceDetails?id=${id}`;
  open(url);
};

const PAGE_SIZE = isMobile.phone ? 10 : 20;

function Resource() {
  const [resourceTabActive, setResourceTabActive] = useState(0);
  const [resourceList, setResourceList] = useState<any[]>([]);
  const filteredResourceList = useMemo(
    () => resourceList?.filter((item) => !isEmptyObj(item) && !getIsAD(item)),
    [resourceList]
  );

  // 懒加载处理
  const refBg = useRef<HTMLDivElement>(null);
  useLazyLoad(refBg, 200)
  const refImgFill = useRef<HTMLDivElement>(null);
  useLazyLoad(refImgFill, 200)

  useEffect(() => {
    handleGetSquareList({
      isPublished: true,
      needThumbnail: true,
      pageSize: PAGE_SIZE,
    })
      .then((res) => {
        res.list && setResourceList(res.list);
      })
      .catch((err) => {
        logger.log("error", `handleGetSquareList err ->> ${err}`);
      });
    initGsap();
  }, []);

  const onTabClick = (item: any, i: number) => {
    if (resourceTabActive == i) {
      return;
    }
    setResourceTabActive(i);

    if (i == 0) {
      getPublishSelectedList({
        pageSize: PAGE_SIZE,
      })
        .then((res) => {
          res.list && setResourceList(res.list);
        })
        .catch((err) => {
          logger.log("error", `getPublishSelectedList err ->> ${err}`);
        });
    } else {
      handleGetSquareList({
        classify: item.type,
        isPublished: true,
        needThumbnail: true,
        pageSize: PAGE_SIZE,
      })
        .then((res) => {
          res.list && setResourceList(res.list);
        })
        .catch((err) => {
          logger.log("error", `handleGetSquareList err ->> ${err}`);
        });
    }
  };

  return (
    <div
      className={cn({
        resource: true,
        [styles.resource]: true,
      })}>
      <div className={styles["resource-wrap"]}>
        <div className={styles["resource-wrap-top"]}>
          <div className={styles.title}>
            <div>共同创造</div>
          </div>
          <PcPrimaryTitle subTitle="即时设计社区" title="" />
          {/* {!isMobile.phone && useRenderATag('/square')} */}
        </div>

        <div className={styles["resource-wrap-bottom"]}>
          {!isMobile.phone && (
            <div
              className="bg"
              data-background={`url(${resourceBigBg})`}
              ref={refBg}
            />
          )}

          <div
            className={cn({
              "js-build-in": true,
              "build-in-scale-fade": true,
              [styles["resource-wrap_btm"]]: true,
            })}>
            <div className={styles["resource-card"]}>
              {!isMobile.phone && (
                <div
                  className={styles['img-fill']}
                  data-background={`url(${point})`}
                  ref={refImgFill}></div>
              )}
              <div className={styles.title}>资源广场</div>
              <div className={styles.title1}>
                <div
                  className={cn({
                    [styles.text]: true,
                    [styles.text1]: true,
                  })}>
                  即拿即用，
                </div>
                <div
                  className={cn({
                    [styles.text]: true,
                    [styles.text2]: true,
                  })}>
                  精选设计资源
                </div>
              </div>
              <ResourceStatistic className={styles["statistic-wrap"]} />
              {isMobile.phone && (
                <div className={styles["phone-resource-scroll-wrap"]}>
                  <div className={styles["resource-scroll-wrap"]}>
                    <div
                      className={cn({
                        "resource-scroll-1": true,
                        [styles["resource-wrap_btm_covers"]]: true,
                      })}>
                      {filteredResourceList?.map((item, index: any) => (
                        <ResourceCardContent
                          key={index}
                          className={styles["scroll-card"]}
                          resource={item}
                          forbiddenAD
                          onClick={() => gotoOneWork(item._id)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <ResourceBtn
                className={styles.resourceBtn}
                title="资源广场"
                onClick={() => {
                  gotoCommunityPage("explore", {}, true);
                }}
              />
            </div>

            {!isMobile.phone && (
              <div className={styles["resource-scroll-wrap"]}>
                <div
                  className={cn({
                    "resource-scroll-1": true,
                    [styles["resource-wrap_btm_covers"]]: true,
                  })}>
                  {filteredResourceList?.map((item, index: any) => (
                    <ResourceCardContent
                      key={index}
                      className={styles["scroll-card"]}
                      resource={item}
                      forbiddenAD
                      onClick={() => gotoOneWork(item._id)}
                    />
                  ))}
                </div>
              </div>
            )}

            {!isMobile.phone && (
              <div className={styles["resource-wrap_top_right-wrap"]}>
                <div className={styles["resource-wrap_top_right"]}>
                  <TabFilter
                    tabData={RESOURCE_TABS}
                    activeTabIndex={resourceTabActive}
                    onTabClick={onTabClick}
                    darkTheme
                  />
                </div>
              </div>
            )}
          </div>

          <div className={styles["two-card"]}>
            {isMobile.phone && (
              <LazyImg src={twoCardBg} className={styles["two-card-bg"]} />
            )}
            <div className={styles["left"]}>
              <LeftCard />
            </div>
            <div className={styles["right"]}>
              <RightCard />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Resource;
