import { useState, useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import cn from "classnames";

import CompareCard from "./CompareCard";
import { isMobile } from "@utils/is";
import { useUserStore } from "src/store/user";
import { goto } from "src/WithRouter";
import { COMPARE_DATA, COMPARE_SVG_LIST } from "@pages/Home/constant";
import PcPrimaryTitle from "@components/PrimaryTitle/Pc";
import PrimaryButton from "@components/PrimaryButton";

import styles from "./index.module.less";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

function Compare() {
  const { _id } = useUserStore();
  const [compareHoverId, setCompareHoverId] = useState<null | number>(null);

  useEffect(() => {
    if (isMobile.phone) return;
    let compareTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".compare-wrap_btm",
        pin: true,
        pinSpacing: true,
        start: "+=40% center",
        end: "bottom center",
        markers: false,
        id: "compare-wrap_btm",
        scrub: true,
      },
    });

    compareTl.to(
      ".compare-other-drag-wrap",
      {
        xPercent: -39,
      },
      "1"
    );
  }, []);

  const freeUse = () => {
    !!_id ? goto("/workspace") : goto("/login?type=register&by=phone");
  };

  const compareMouseEnterHandle = (i: number) => {
    setCompareHoverId(i);
  };
  const compareMouseLeaveHandle = () => {
    setCompareHoverId(null);
  };

  return (
    <div className={styles.compare}>
      <div
        className={cn({
          "view-1": true,
          [styles["compare-wrap"]]: true,
        })}>
        <div
          className={cn({
            "view-2": true,
            [styles["compare-wrap_top"]]: true,
          })}>
          <div>
            <PcPrimaryTitle
              className={styles.title}
              title="对比同领域软件"
              subTitle="次世代设计工具"
            />
            {/* {!isMobile.phone && useRenderATag(
              UserStore.isLogin() ? '/workspace' : '/login?type=register&by=phone'
            )} */}
            {!isMobile.phone && (
              <PrimaryButton
                style={{
                  width: "160px",
                  height: "4rem",
                }}
                text="免费使用"
                className={styles.button}
                onClick={freeUse}
              />
            )}
          </div>
          <div className={styles.description}>
            <strong>
              即时设计颠覆传统设计软件的形态，将设计师与设计师、与产品、与开发，
            </strong>
            <p>
              通过线上协作网络联结起来，让设计师不再孤岛式作业，同时涵盖所有传统工具功能，为广大产研群体提供更具价值的服务。
            </p>
          </div>
        </div>
      </div>
      <div
        className={cn({
          "view-1": true,
          "compare-wrap_btm": true,
          [styles["compare-wrap_btm"]]: true,
        })}>
        <CompareCard
          compareItem={COMPARE_DATA[0]}
          supportIcon={COMPARE_SVG_LIST.support}
          primary
          compareHoverId={compareHoverId}
          compareMouseEnterHandle={compareMouseEnterHandle}
          compareMouseLeaveHandle={compareMouseLeaveHandle}
        />
        <div className={styles["compare-other"]}>
          <div
            className={cn({
              "compare-other-drag-wrap": true,
              [styles["compare-other-drag-wrap"]]: true,
            })}>
            {COMPARE_DATA.map((item, index) => {
              return index > 0 ? (
                <CompareCard
                  key={item.title}
                  className={styles["compare-card"]}
                  compareItem={item}
                  supportIcon={COMPARE_SVG_LIST.supportLine}
                  compareHoverId={compareHoverId}
                  compareMouseEnterHandle={compareMouseEnterHandle}
                  compareMouseLeaveHandle={compareMouseLeaveHandle}
                />
              ) : null;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compare;
