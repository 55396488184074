import { NavigateFunction, useNavigate } from "react-router-dom";

let goto: NavigateFunction;

function WithRouter() {
  const navigate = useNavigate();
  goto = navigate;

  return <></>;
}

export { goto, WithRouter };
