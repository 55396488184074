import { useMemo } from "react";

import { checkIsDingTalkPc } from "@utils/judge-platform";
import LazyImg from "@components/LazyImg";
import LazyVideo from "@components/LazyVideo";

import styles from "./index.module.less";

interface I_Props {
  date: string;
  title: string;
  des: string;
  url?: string;
  img: string;
  color?: string;
  bgImg?: string;
  gif?: any;
  video?: any;
}

export default function UpdateCard(props: I_Props) {
  const { title, des, url, img, date, color, bgImg, gif, video } = props;
  const isDingTalk = useMemo(() => checkIsDingTalkPc(), []);

  return (
    <div className={styles["update-card"]}>
      <div
        onClick={() => {
          if (isDingTalk) return;
          if (url) open(url);
        }}>
        <div
          className={styles["updated_card_cover"]}
          style={{
            ...(bgImg
              ? { background: `url(${bgImg})` }
              : { ...(color ? { background: color } : {}) }),
          }}>
          <div className={styles["cover-img"]}>
            <LazyImg src={gif || img} width={gif ? "100%" : "80%"} />
          </div>
          {video && (
            <LazyVideo className={styles.video} src={video.src} {...video} />
          )}
        </div>
        <div className={styles["updated_card_content"]}>
          <span>{date}</span>
          <h6>{title}</h6>
          <p>{des}</p>
        </div>
      </div>
    </div>
  );
}
