import { nanoid } from "nanoid";
import dayjs from "dayjs";

import { checkIsInDingTalkPcOrFeiShuPc } from "@utils/judge-platform";
import { checkIsNotReferrer } from "@utils/window/judge";
import { post } from "@utils/https";
import { isInPc } from "@utils/pc";
import { actionToTheme } from "./constant";
import { userState_memorize } from "src/store/user";
import { getOS } from "@utils/get";
import { checkIsRegisterSuccessFromExpand } from "./expand";
import { stringToObj } from "@utils/parse";
import { isEmptyObj } from "@utils/is";

const isPageEmbeddedAndNoJsd = () =>
    checkIsNotReferrer() && window.location.host !== window.jsHost;

/**
 * @name: 数据仓库埋点
 * @test: test font
 * @msg:
 * @param {any} _option
 * @return {*}
 */
function dataWarehouse(_option: Record<string, any>, time?: number) {
    //   if (isDevMode()) {
    //     // 开发者模式/线下测试环境
    //     const DATA_WAREHOUSE = DebugSwitchStore.isEnable('DATA_WAREHOUSE');
    //     if (!DATA_WAREHOUSE) return;
    //   }

    if (isPageEmbeddedAndNoJsd() || checkIsInDingTalkPcOrFeiShuPc()) return;

    const lastTime = time || 0;

    const dataSession = window.localStorage.getItem("dataSession");
    let dataSessionObj: any = {};
    // 当不存在dataSession时生成
    if (!dataSession) {
        const sessionInfo = {
            sessionTime: new Date(
                new Date().getTime() +
                (new Date().getTimezoneOffset() / 60 + 8) * 3600 * 1000
            ).getTime(),
            sessionId: nanoid(),
            count: 1,
        };
        window.localStorage.setItem("dataSession", JSON.stringify(sessionInfo));
        dataSessionObj = sessionInfo;
        // 当失败后重复发送不记录count
    } else if (lastTime == 0) {
        //当存在时，count计数 加 1
        dataSessionObj = JSON.parse(dataSession);
        const curSessionTime = new Date(
            new Date().getTime() +
            (new Date().getTimezoneOffset() / 60 + 8) * 3600 * 1000
        ).getTime();
        const minuteDiff =
            (curSessionTime - dataSessionObj.sessionTime) / (1000 * 60);
        dataSessionObj.count = dataSessionObj.count + 1;
        //当超过30分钟时 重新生成session
        if (minuteDiff > 30) {
            dataSessionObj = {
                sessionTime: curSessionTime,
                sessionId: nanoid(),
                count: 1,
            };
        }
        window.localStorage.setItem("dataSession", JSON.stringify(dataSessionObj));
    }
    const data = JSON.stringify({ ..._option, ...dataSessionObj });
    const url = window.jsDataUrl;

    const result = navigator.sendBeacon(url, data);
    // 当sendBeacon方法异常时，使用ajax兜底
    if (!result) {
        post({
            url,
            data,
            config: {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        });
    }
}

/**
 * @name:
 * @test: 填入数据参数
 * @msg:
 * @param {string} action action
 * @param {any} propertiesNew properties参数对象
 * @param {string} theme 主题
 * @param {string} userId 用户id
 * @param {string} nickname 用户名
 * @param {string} phone 手机
 * @return {*}
 */
function getOption(
    action: string,
    propertiesNew?: any,
    theme?: string,
    userId?: string,
    nickname?: string,
    phone?: string
) {
    const isPC = isInPc();

    const _properties = {
        user_agent: window.navigator.userAgent,
        device_type: isPC ? "pc" : "web",
        os: getOS(),
        browser_window_size: window.innerWidth + "x" + window.innerHeight,
        screen_resolution: window.screen.width + "x" + window.screen.height,
        screen_magnification: window.devicePixelRatio,
        ...propertiesNew,
    };

    const currUserId = userId ? userId : userState_memorize?._id ? userState_memorize?._id : null;

    const option = {
        ...(theme
            ? { theme }
            : getActionToTheme(action, actionToTheme)
                ? { theme: getActionToTheme(action, actionToTheme) }
                : {}),
        action,
        distinct_id: nanoid(),
        time: new Date(
            new Date().getTime() +
            (new Date().getTimezoneOffset() / 60 + 8) * 3600 * 1000
        ).getTime(),
        user_id: currUserId,
        ...(nickname
            ? {
                nickname,
            }
            : {}),
        ...(phone
            ? {
                phone,
            }
            : {}),
        hrefUrl: window.location.href,
        properties: _properties,
    };

    return option;
}

/**
 * @name: 获得对应的action的theme
 * @test: test font
 * @msg:
 * @param {string} action
 * @return {*}
 */
function getActionToTheme(action: string, actionToThemeObj: any) {
    let theme = null;
    Object.keys(actionToThemeObj).forEach((item) => {
        if (item.toLowerCase() == action.toLowerCase()) {
            theme = actionToThemeObj[item];
        }
    });
    return theme;
}

function getIsLoadPage() {
    const { isFromExpand, resource, plan } = checkIsRegisterSuccessFromExpand();
    const curSource = isFromExpand ? resource : null;
    const curPlan = isFromExpand ? plan : null;
    if (!getFirstEnterUrl().firstEnterUrl) {
        dataWarehouse(getOption('loadPage', { source: curSource, plan: curPlan }));
    }
}

function getFirstEnterUrl(): {
    firstEnterUrl?: string;
    eqId?: string;
} {
    let curFirstEnterUrl = '';
    let _referrer = '';
    const firstEnter: any = stringToObj(window.localStorage.firstEnter);
    if (firstEnter && !isEmptyObj(firstEnter)) {
        const { createTime, firstEnterUrl, referrer } = firstEnter;
        const timeDiff = dayjs(new Date().toLocaleDateString()).diff(
            dayjs(new Date(createTime).toLocaleDateString()),
            'day'
        );
        if (timeDiff == 0) {
            curFirstEnterUrl = firstEnterUrl;
            _referrer = referrer;
        }
    }
    return {
        ...(curFirstEnterUrl
            ? {
                firstEnterUrl: curFirstEnterUrl,
                eqId: _referrer || document.referrer,
            }
            : {}),
    };
}

export {
    dataWarehouse,
    getOption,
    getIsLoadPage,
    getFirstEnterUrl
}

