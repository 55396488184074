import instance from "./base";
import { I_RequestParams, I_ResponseData } from "./types";

export const post = <T = any>({ url = "", data, config = {} }: I_RequestParams) => {
  return new Promise((resolve: (value: I_ResponseData<T>) => void, reject) => {
    instance.post(url, data, config).then(
      (response: any) => {
        resolve(response);
      },
      (err) => {
        reject(err);
      }
    );
  });
};
