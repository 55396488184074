import { useCallback } from "react";
import cn from "classnames";

import { useNotifications, useNotificationsDispatch } from "./store";
import { preventDefault } from "@utils/event";
import { lazyAdapter } from "@components/LazyLoad";

import styles from "./index.module.less";

const NotificationsHeader: any = lazyAdapter(
  () => import("./components/NotificationsHeader")
);
const NotificationsContent: any = lazyAdapter(
  () => import("./components/NotificationsContent")
);

const top = 100;

const NotificationsPanel = () => {
  const notificationsContext = useNotifications();
  const notificationsDispatch = useNotificationsDispatch();
  const { isShow, isShowDropFilter, hoverElementPos } = notificationsContext;

  const clickNotificationsPanel = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      preventDefault(e);
      if (!isShowDropFilter) return;
      notificationsDispatch({
        type: "changeIsShowDropFilter",
        payload: {
          isShow: false,
        },
      });
    },
    [isShowDropFilter]
  );

  if (!isShow) return <></>;

  return (
    <div
      className={cn({
        [styles.panel]: true,
        [styles.showPanel]: isShow,
      })}
      onClick={clickNotificationsPanel}
      style={
        top
          ? {
              top: hoverElementPos?.bottom + 10 + "px",
              left: hoverElementPos?.right - 210 + "px",
            }
          : {}
      }
    >
      <NotificationsHeader />
      <NotificationsContent top={top} />
    </div>
  );
};

export default NotificationsPanel;
