import { createContext, Dispatch, useContext } from "react";

const PublishStore = createContext<I_Publish.State | null>(null);

const PublishDispatchStore = createContext<Dispatch<I_Publish.Actions> | null>(null);

function usePublishStore() {
  return useContext(PublishStore) as I_Publish.State;
}

function usePublishDispatchStore() {
  return useContext(PublishDispatchStore) as Dispatch<I_Publish.Actions>;
}

export { PublishStore, PublishDispatchStore, usePublishStore, usePublishDispatchStore };
