import LazyImg from "@components/LazyImg";
import {
  A1IntroImgs,
  fourthLeftBg,
  fourthLeftImg,
  fourthLeftWrapperBg,
} from "./constant";
import styles from "./index.module.less";
import AiModal from "./AiModal";
import Button from "@components/Button";
import art_icon from "@svg/art_btn.svg";
import hoverIcon from "@img/magic.png";
import { isMobile } from "@utils/is";
import { aiBgImg, aiLog } from "@pages/Home/constant";

const FourthScreen = () => {
  return (
    <>
      <div
        className={
          isMobile.phone ? styles.secondParagraphMobile : styles.secondParagraph
        }
      >
        <LazyImg src={aiBgImg} alt="img" className={styles.aiBg} />
        <div className={styles.wrapper}>
          <div className={styles.logWrapper}>
            <LazyImg src={aiLog} className={styles.aiLog} />
          </div>
          <div className={styles.info}>
            <h1>
              <strong>AI</strong>
              设计纪元
            </h1>
            <p>打破传统设计界限，无限释放设计灵感</p>
          </div>
        </div>
      </div>
      <div
        className={
          isMobile.phone ? styles.fourthScreenMobile : styles.fourthScreen
        }
      >
        <div
          className={styles.left}
          style={{
            padding: "12px",
          }}
        >
          <div
            style={{
              backgroundImage: `url(${fourthLeftWrapperBg})`,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div
              className={styles.wrapper}
              style={{
                backgroundImage: `url(${fourthLeftBg})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "contain",
              }}
            >
              <h1>即时 AI</h1>
              <p>
                一句话生成
                <br />
                可编辑的 UI 设计稿
              </p>
              <LazyImg
                className={styles.cover}
                src={fourthLeftImg}
                alt="图片"
              />
              {!isMobile.phone && <AiModal />}
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.slideshow}>
            {[...A1IntroImgs, ...A1IntroImgs].map((item, i) => {
              return <LazyImg key={i} src={item.url} alt="img" />;
            })}
          </div>
          <div className={styles.title}>
            <span>A1.art</span>
            <span>AI 图像生成应用平台</span>
          </div>
          {!isMobile.phone ? (
            <div className={styles.slogan}>
              一张图/一个词 立即生成高质量图像
            </div>
          ) : (
            <div className={styles.slogan}>
              一张图/一个词
              <br />
              立即生成高质量图像
            </div>
          )}
          {!isMobile.phone && (
            <div className={styles.operation}>
              <Button
                className={styles.customBtn}
                styles={{
                  cursor: `url(${hoverIcon}) -4 -4, pointer`,
                }}
                onClick={() => window.open(`https://a1.art/?source=js&plan=sy`)}
              >
                <div className={styles.btnText}>
                  <LazyImg src={art_icon} alt="icon" />
                  免费体验
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FourthScreen;
