export const actionToTheme: Record<string, string> = {
  reach_access_file: "behavior",
  iconpark: "plugin",
  persona: "portrait",
  iconfont: "plugin",
  iconify: "plugin",
  blobshapetocanvas: "plugin",
  findreplacetextcontent: "plugin",
  polygon: "basic_functions",
  frame: "basic_functions",
  arrow: "basic_functions",
  rectangle: "basic_functions",
  triangle: "basic_functions",
  createteam: "behaviour",
  createsymbolcontr: "advanced_function",
  text: "basic_functions",
  createfile: "behaviour",
  pentagram: "basic_functions",
  circular: "basic_functions",
  line: "basic_functions",
  export: "advanced_function",
  importimage: "basic_functions",
  importfile: "behaviour",
  userpath: "arrive",
  alignment: "advanced_function",
  sharedupdate: "advanced_function",
  banner: "behaviour",
  addelementgradient: "plugin",
  removeteam: "behaviour",
  preview: "advanced_function",
  quickcopy: "plugin",
  batchchangename: "plugin",
  discuss: "advanced_function",
  curvettexthistory: "plugin",
  deletefile: "behaviour",
  penuse: "basic_functions",
  borders: "advanced_function",
  addelementblurval: "advanced_function",
  penciluse: "basic_functions",
  gridframe: "advanced_function",
  addelementsshadow: "advanced_function",
  addsymbolcomp: "advanced_function",
  firstload: "behaviour",
  iconresize: "plugin",
  creategroupbyimagecolorext: "plugin",
  appearance: "advanced_function",
  textsplitter: "plugin",
  translatetext: "plugin",
  replacefont: "plugin",
  download: "behaviour",
  downloadziyou: "plugin",
  rotatecopycreateelement: "plugin",
  cleandocument: "plugin",
  autologin: "behaviour",
  imgnoisefill: "plugin",
  imagematting: "plugin",
  smartfill: "plugin",
  createaxonometricplane: "plugin",
  sharecollaboration: "behaviour",
  squaretab: "behaviour",
  search: "behaviour",
  resourcessquare: "behaviour",
  AutoLayoutInRightMenu: "advanced_function",
  fontuse: "advanced_function",
  register: "behaviour",
  workspaceguidance: "behaviour",
  TDesign: "plugin",
  useZiYou: "plugin",
  share: "behaviour",
  "Resource Recommendation": "behaviour",
  TD: "plugin",
  guide: "behaviour",
  "AI wipe": "plugin",
  permission_management: "behaviour",
  match_click: "behaviour",
  enter_project: "behaviour",
  starmark_board: "behaviour",
  starmark: "behaviour",
  recycle_bin: "behaviour",
  resource_use: "recommend",
  resource_comment: "recommend",
  resource_like: "recommend",
  resource_share: "recommend",
  resource_view: "recommend",
  start: "plugin", // 插件启动埋点
  plugin_install: "recommend",
  plugin_like: "recommend",
  plugin_comment: "recommend",
  plugin_share: "recommend",
  plugin_view: "recommend",
  activity_click: "behaviour",
  xiaoyangding: "plugin",
  follow: "recommend",
  widget_like: "recommend",
  widget_share: "recommend",
  widget_comment: "recommend",
  widget_view: "recommend",
  widget_use: "recommend",
  plugin_search: "behaviour",
  addOneFill: "advanced_function",
  closeGuide: "behaviour",
  assembly_use: "company",
  assembly_unbinding: "company",
  error: "report",
  ai: "behaviour",
  ai_share: "behaviour",
  publiclink_open: "behaviour", // 链接分享开关开启
  publiclink_open_copylink: "behaviour", // 链接分享开关开启点击复制链接
  publiclink_close: "behaviour", // 链接分享开关关闭
  invite_search_view: "behaviour", // 邀请协作者搜索结果页曝光
  invite_button_click: "behaviour", // 邀请协作按钮点击
  invitelist_group_memberlist: "", // 协作者列表查看团队成员
  invitelist_permission_change: "", // 协作者列表变更权限
  invitelist_leave: "behaviour", // 协作者列表退出协作
  invitelist_remove: "behaviour", // 协作者列表移出权限
  link: "behaviour", // 复制文件URL
  ercode_view: "behaviour", // 二维码曝光
  privatization_landing: "behaviour",
  pricingpage_arrive: "arrive", // 用户访问定价页
  enterprise_open: "behaviour", // 点击开通企业版按钮
  selectpopup_open: "behaviour", // 企业账号添加方式选择弹窗被打开
  join_company_click: "behaviour", // 企业账号添加方式加入企业卡片被点击
  create_company_click: "behaviour", // 企业账号添加方式创建企业卡片被点击
  migrate_popup_open: "arrive", // 访问迁移团队页面
  not_migrate_click: "behaviour", // 不迁移团队直接创建企业
  migrate_click: "behaviour", // 点击迁移团队按钮
  create_prototype_container: "behaviour", // 创建原型容器
  enter_prototype_toolbar: "behaviour", // 从属性工具条进入原型模式
  homeage_preview: "behaviour",
  click_price: "behaviour",
  click_help: "behaviour",
  click_discord: "behaviour",
  click_footer_termofuse: "behaviour",
  click_footer_privacy: "behaviour",
  click_register: "register",
  finish_register: "register",
  click_help_center: "behaviour",
  p_intl_pricing_view: "hrefurl",
  p_intl_pricing_workspace: "hrefurl",
  e_intl_click_pricingMonthly: "behaviour",
  e_intl_click_pricingSubscribe: "behaviour",
  e_intl_click_pricingStrip: "behaviour",
  e_intl_click_pricinguncancel: "behaviour", //定价页点击uncancel
  p_intl_pricing_resumePre_pop: "hrefurl", //套餐弹层
  e_intl_click_resumePre_confirm: "behaviour", //确认回复套餐弹层
  click_X: "behaviour",
  "click_what's_new": "behaviour",
  click_video_play: "behaviour",
};
