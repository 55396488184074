import { helpCode } from "@pages/Home/constant";
import styles from "./index.module.less";
import InlineSVG from "svg-inline-react";
import { helpBtn } from "@svg/home";
import LazyImg from "@components/LazyImg";

const Help = () => {
  return (
    <div className={styles.help}>
      <div className={styles.qrCode}>
        <LazyImg src={helpCode} alt="qrcode" />
      </div>
      <div className={styles.helpBtn}>
        <InlineSVG src={helpBtn} />
      </div>
    </div>
  );
};

export default Help;
