import { publishTotalConvert } from "@pages/Home/handle";
import { useEffect, useState } from "react";

import { handleGetSource } from "src/request/resource";
import { usePublishDispatchStore, usePublishStore } from "src/store/publish";
import { logger } from "@utils/logger";
import { isEmptyObj } from "@utils/is";

import styles from './index.module.less';

function ResourceStatistic({
  className,
}: {
  className: string;
}) {
  const { isUpdate, ...other } = usePublishStore();
  const publishDispatch = usePublishDispatchStore();
  const [stateData, setStateData] = useState(publishTotalConvert(other));

  useEffect(() => {
    if (!isUpdate) {
      handleGetSource().then((res: any) => {
        if (res.code == 0) {
          if(!res.data || isEmptyObj(res.data)) return;
          setStateData(publishTotalConvert(res.data));
          publishDispatch({
            type: "setTotal",
            payload: res.data,
          });
        }
      }).catch(err => {
        logger.log('error', `handleGetSource err ->> ${err}`)
      })
    }
  }, []);

  return (
    <div className={className}>
      {stateData?.map((item) => (
        <div key={item.name} className={styles['statistic-item']}>
          <span>{item.data}</span>
          <p>{item.name}</p>
        </div>
      ))}
    </div>
  );
}

export default ResourceStatistic;