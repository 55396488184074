import { useRef, useEffect, useCallback } from "react";
import cn from "classnames";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { PLUGIN_TABS } from "@pages/Home/constant";
import LazyImg from "@components/LazyImg";

import styles from "./index.module.less";
import { BaseIcon } from "@components/BaseIcon";

if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

interface ITabData {
  title: string;
  icon: any;
  type: string;
}

interface I_Props {
  tabData: ITabData[];
  activeTabIndex: number;
  onTabClick: Function;
  darkTheme?: boolean;
  isRow?: boolean;
  className?: any;
  left?: string;
  identify?: string;
  allNum?: number;
  hasSearchPlugin?: boolean;
  total?: number;
}

function TabFilter(props: I_Props) {
  const {
    tabData,
    activeTabIndex,
    onTabClick,
    darkTheme = false,
    className,
    left,
    identify = "",
    allNum,
    hasSearchPlugin,
    total,
  } = props;
  const tabIndicator = useRef(null);
  const initUpdate = useRef(true);

  useEffect(() => {
    if (tabIndicator.current) {
      // 初始化 TabIndicator 界面
      // 如果不通过 setTimeout, onTabClick 内部 位置计算会有偏差
      setTimeout(() => moveIndicator(activeTabIndex, 0), 550);
    }
  }, [activeTabIndex]);

  useEffect(() => {
    window.addEventListener("resize", resize);

    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [activeTabIndex]);

  const resize = () => {
    setTimeout(() => {
      moveIndicator(activeTabIndex, 0);
    }, 550);
  };

  const handleTabClick = (item: any, index: number) => {
    moveIndicator(index, 0.3);
    onTabClick(item, index);
  };

  const moveIndicator = useCallback(
    (index: number, duration = 0.3) => {
      let tabItem = document.querySelectorAll(`.tab-item-${identify}`)[
        index
      ] as HTMLElement;
      if (tabItem) {
        let tabWidth = tabItem?.offsetWidth;
        let tabXPos = index == 1 && hasSearchPlugin ? 76 : tabItem?.offsetLeft;

        gsap.to(tabIndicator.current, {
          duration,
          left:
            (left ? (index == 0 ? parseInt(left) : -parseInt(left)) : 0) +
            tabXPos,
          width: index == 0 && hasSearchPlugin ? "83px" : tabWidth,
          ease: "back.out(0.5)",
        });
      }
    },
    [identify]
  );

  // function getIndex(classify: string) {
  //   if (classify == "") classify = "all";
  //   return PLUGIN_TABS.findIndex((i: any) => i.type == classify);
  // }

    // useEffect(() => {
    //   if (hasSearchPlugin) {
    //     const classify = getZhUrlParam('classify');
    //     moveIndicator(getIndex(classify), 0.3);
    //   }
    // }, [allNum, total, !!getZhUrlParam('search'), getZhUrlParam('classify')]);

  return (
    <div
      className={cn({
        "resource-tab": true,
        [styles["tab-filter"]]: true,
        [styles["dark-tab"]]: darkTheme,
        [className]: true,
      })}>
      <div
        className={styles.indicator}
        id="resource-tab-indicator"
        ref={tabIndicator}
      />
      {tabData.map((item, index) => {
        return (
          <div
            key={item.title}
            onClick={() => {
              handleTabClick(item, index);
            }}
            className={cn({
              //   [cursorStyle['cursor-pointer']]: true,
              [styles["tab-item"]]: true,
              [`tab-item-${identify}`]: true,
              "no-selected-tab-item": activeTabIndex !== index,
              [styles["tab-active"]]: activeTabIndex === index,
            })}>
            <div className={styles["tab-icon"]}>
              <BaseIcon size={20} iconSvg={item.icon} />
            </div>

            <p className={styles["tab-text"]}>{item.title}</p>
          </div>
        );
      })}
    </div>
  );
}

export default TabFilter;
