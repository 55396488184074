const appElementAddClass = () => {
    const appElement = document.getElementById("app");    
    if (appElement && !appElement.classList.value.includes('app')) {
        appElement.classList.add('app');
    }
};

const appElementRemoveClass = () => {
    const appElement = document.getElementById("app");
    if (appElement) {
        appElement.classList.remove('app');
    }
};

export {
    appElementAddClass,
    appElementRemoveClass
}