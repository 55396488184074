import React, { useState } from "react";
export default function ErrorBoundary({ children }: any) {
  const [hasError, setHasError] = useState(false);
  if (hasError) {
    // 渲染备用 UI
    return <h1>Something went wrong.</h1>;
  }

  // 正常渲染子组件
  return children;
}
