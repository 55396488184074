import { LOGIN_ACTION, LOGIN_OUT_MARK } from "@utils/change-user";
import { post } from "@utils/https";

interface IPickAccountParams {
  newUserId?: string;
  changeType?: "enterpriseUserInit" | "";
}

const changeUser = (
  userId: string,
  accountStatus?: I_User.ACCOUNT_STATUS,
  params?: IPickAccountParams
): Promise<any> => {
  return new Promise((resolve, reject) => {
    post({
      url: "/api/user/v1/logout",
      data: JSON.stringify({ userId, accountStatus, ...params }),
    })
      .then((res: any) => {
        const { code } = res;

        if (code == 0) {
          // await syncAndGetLanguageSetting({
          //   noEmit: checkIsFromEnterpriseStep(),
          // });

          sessionStorage.clear();
          window.localStorage.setItem(
            LOGIN_OUT_MARK,
            LOGIN_ACTION.toggleAccount
          );
          setTimeout(() => {
            window.localStorage.setItem(LOGIN_OUT_MARK, LOGIN_ACTION.online);
            resolve(res);
          }, 0);
        } else {
          reject(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export { changeUser };
