import Garfish from "garfish";
import { getMasterAppInfo } from "./Externals/getMasterInfo";
import { AppNameMap } from "./app";

// 初始化框架配置
export const initGarfish = (props = {} as { external?: {} }) => {
  // 给子应用注入依赖
  Garfish.setExternal({
    ...(props.external || {}),
    getMasterAppInfo,
  });
  // garfish全局配置
  Garfish.setOptions({
    // vm沙箱时，garfish会拦截并给每个应用划分各自的作用域
    // 想让全局的某些属性可以在全部应用内都使用则需要在这里注册对应的变量
    protectVariable: [
      // 目前不走vm沙箱，所以这里暂时不启用
      "jsServerUrl",
      "jsUrl",
      "jsStaticUrl",
      "jsStaticResourceUrl",
      "isDevInHtml",
      "jsGet",
      "$",
      "mouseDownTarget",
    ],
    sandbox: {
      // 使用快照沙箱
      snapshot: true,
    },
    afterUnmount(app, appInstance) {
      //"jsDesign"
      console.info("afterUnmount app", app, appInstance);
      // console.log('app',app, appInstance)
      console.log("卸载子应用完成", app, appInstance);
    },
    beforeMount(app, appInstance) {
      // AppNameMap.jsDesign
      // let appElement = document
      console.info("beforeMount app", app, appInstance);
    },
    errorLoadApp(erroInfo, appInfo) {
      console.log("加载失败", erroInfo, appInfo);
      window?.location?.reload?.();
    },
    errorMountApp(erroInfo, appInfo) {
      console.log("挂载失败", erroInfo, appInfo);
      window?.location?.reload?.();
    },
  });
};
