/**
 * 获取native loading dom
 */
const getNativeLoadingDom = function () {
    return document.getElementById('native-loading')
}

/**
 * 显示native loading
 */
const showNativeLoading = function () {
    const d = getNativeLoadingDom()
    if (d) {
        d.style.display = 'block'
    }
}

/**
 * 隐藏native loading
 */
const hideNativeLoading = function () {
    const d = getNativeLoadingDom()
    if (d) {
        d.style.display = 'none'
    }
}

export {
    showNativeLoading,
    hideNativeLoading,
}