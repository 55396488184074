import cn from "classnames";

import { TypeCompareItem, COMPARE_DATA } from "@pages/Home/constant";

import styles from "./index.module.less";
import { BaseIcon } from "@components/BaseIcon";

interface I_Props {
  className?: string;
  compareItem: TypeCompareItem;
  supportIcon: any;
  primary?: boolean;
  compareHoverId: number | null;
  compareMouseEnterHandle: Function;
  compareMouseLeaveHandle: Function;
}

function CompareCard(props: I_Props) {
  const {
    className,
    compareItem,
    supportIcon,
    primary,
    compareHoverId,
    compareMouseEnterHandle,
    compareMouseLeaveHandle,
  } = props;

  return (
    <section
      className={cn({
        [styles.compare]: true,
        [styles["primary-compare-card"]]: primary,
        ...(className ? { [className]: true } : {}),
      })}
    >
      <div className={styles["compare-card_top"]}>
        <div className={styles["compare-card_top_logo"]}>
          <BaseIcon iconSvg={compareItem.logo} size={32} topIcon={true} />
        </div>
        <span>{compareItem.title}</span>
      </div>
      <ul className={styles["compare-card_btm"]}>
        {compareItem.list.map((item, index) => (
          <li
            key={item.title}
            className={cn({
              [styles["compare-hover-bg"]]:
                compareHoverId === index &&
                compareItem.title === COMPARE_DATA[0].title,
              [styles["compare-hover-bg-other"]]:
                compareHoverId === index &&
                compareItem.title !== COMPARE_DATA[0].title,
            })}
            onMouseEnter={() => compareMouseEnterHandle(index)}
            onMouseLeave={() => compareMouseLeaveHandle()}
          >
            <p
              className={cn({
                [styles["no-support"]]: !item.support,
              })}
            >
              {item.title}
            </p>
            {item.support ? (
              <i>
                <BaseIcon iconSvg={supportIcon} />
              </i>
            ) : (item as any)?.note ? (
              <span className={cn({ [styles["no-support"]]: !item.support })}>
                {(item as any)?.note}
              </span>
            ) : (
              <i className={item.support ? "" : styles["no-support"]}>{"-"}</i>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
}

export default CompareCard;
