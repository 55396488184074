import { post } from "@utils/https";

const handleGetPluginList = ({
  pageNum = 1,
  size = 6,
  sortType = "createDate",
}) => {
  const data = {
    pageNum,
    size,
    sortType,
  };

  return post({
    url: "/plug/list",
    data,
  });
};

export { handleGetPluginList };
