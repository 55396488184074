export function downloadURI(url: string, name: string) {
  const link = document.createElement("a");

  link.download = name;
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

enum ImgSize {
  Small = "Small",
  Normal = "Normal",
  Big = "Big",
}

// 拼接头像地址
export const getAvatarUrl = (url: string, imgSize = ImgSize.Big) => {
  // 微信和飞书头像是完整地址
  if (url.includes("http") || url.includes("https")) {
    return url;
  }
  // 平台注册的值返回了文件名，需要手动拼cdn地址
  return `${(window as any).jsStaticUrl}/assets/avatarImg/${imgSize}${url}`;
};

// 挂载全局样式
// 这里计算内容宽度设置会对老项目编辑器有影响，所以选择性处理

export const mountStyle = () => {
  const style = document.createElement("style");
  style.setAttribute("id", "globalCss");
  const text = document.createTextNode(`
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
    `);
  style.appendChild(text);
  document.querySelector("head")?.appendChild(style);
};

export const unmountStyle = () =>
  document.querySelector("#globalCss")?.remove();
