import { Link } from "react-router-dom";
import {
  figma,
  sketch,
  xd,
} from "../../../../pages/Home/constant";
import pptImg1 from "./img/pptImg1.png";
import styles from "./index.module.less";
import LazyImg from "@components/LazyImg";

export const AdvantageRouteData = [
  {
    img: sketch,
    text: "对比 Sketch",
    href: "/js-vs-sketch",
  },
  {
    img: figma,
    text: "对比 Figma",
    href: "/js-vs-figma",
  },
  {
    img: xd,
    text: "对比 Adobe XD",
    href: "/js-vs-adobe-xd",
  },
];

export const Advantage = () => {
  return (
    <div className={styles.advantage}>
      <div className={styles.cover}>
        <LazyImg src={pptImg1} alt="img" />
      </div>
      <div className={styles.info}>
        <div className={styles.title}>产品对比</div>
        {AdvantageRouteData.map((item) => {
          return (
            <Link key={item.href} to={item.href}>
              <LazyImg src={item.img} alt="icon" />
              <div className={styles.name}>{item.text}</div>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

export const DesignCommunity = () => {
  return (
    <div className={styles.designCommunity}>
      <div className={styles.coverImg}>
        <js-frame
          id="d2c-issueCard"
          src={`${window.jsServerUrl}/elements/nPIrIRBPbBN`}
        />
      </div>
      <div className={styles.infoCard}>
        <js-frame
          id="d2c-newCard"
          src={`${window.jsServerUrl}/elements/U9pSaBuSP-a`}
        />
      </div>
    </div>
  );
};
