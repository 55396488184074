import { useEffect, useMemo, useRef, useState } from "react";
import cn from "classnames";
import { useSize } from "ahooks";

import { isMobile } from "@utils/is";
import useInitGsap from "./useInitGsap";
import LazyImg from "@components/LazyImg";
import { HeroTabList, HeroVideoList } from "@pages/Home/constant";
import useListenBarTop from "@utils/hooks/useListenBarTop";
import RegisterBtn from "../registerBtn";
import { useUserStore } from "src/store/user";

import safariBar from "../../img/safari-bar.png";

import styles from "./index.module.less";

function HeroMotion() {
  const videoDom = useRef<HTMLVideoElement>(null);
  const { _id } = useUserStore();
  const isLogin = !!_id;

  const { seekHeroTl } = useInitGsap(() => {
    videoDom.current?.play();
    (videoDom.current as any).currentTime = 12;
  });

  const { topTipHeight, navBarHeight } = useListenBarTop();
  const topHeight = useMemo(() => {
    return topTipHeight + navBarHeight;
  }, [topTipHeight, navBarHeight]);

  const { width = 1080 } = useSize(document.body) || {};

  const dynamicStyle = useMemo(() => {
    return {
      ...(width <= 640 ? { height: `calc(100vh - ${topHeight}px)` } : {}),
    };
  }, [width, topHeight]);

  // 手动resize一下触发gasp动画
  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 10);
  }, []);
  return (
    <section
      className={cn({
        "hero-section": true,
        [styles["hero-motion"]]: true,
      })}
      id="heroSection"
      style={dynamicStyle}
    >
      {!isMobile.phone && (
        <div
          className={cn({
            "scroll-title": true,
            [styles["scroll-title"]]: true,
          })}
        >
          <div
            className={cn({
              "view-1": true,
              [styles["view-1"]]: true,
            })}
          >
            <div className={styles["scroll-title_wrap"]}>
              <div className={styles["scroll-title_tab"]}>
                {HeroTabList.map((item, index) => (
                  <span
                    key={item.title}
                    data-type="button"
                    className={cn({
                      ["scroll-title_tab-item-" + (index + 1)]: true,
                      [styles["gsap-scroll-title_tab-active"]]: index === 0,
                    })}
                  >
                    <i>
                      <LazyImg
                        src={item.icon}
                        width={64}
                        height={64}
                        className="width-full"
                      />
                    </i>
                    <p onClick={() => seekHeroTl(item.time)}>{item.title}</p>
                  </span>
                ))}
              </div>
              <div className={styles["scroll-title_content-wrap"]}>
                {HeroTabList.map((item, index) => (
                  <div
                    key={item.key}
                    className={cn({
                      "scroll-title_content": true,
                      ["scroll-title_content-item-" + (index + 1)]: true,
                      "scroll-title_content-active": index === 0,
                      [styles["gsap-scroll-title_content"]]: true,
                      // @ts-ignore
                      [styles[`${"scroll-title_content-item-" + (index + 1)}`]]:
                        true,
                      [styles["gsap-scroll-title_content-active"]]: index === 0,
                    })}
                  >
                    <h3>{item.subtitle}</h3>
                    <p>{item.describe}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className={cn({
          "hero-section-bg": true,
          [styles["hero-section-bg"]]: true,
        })}
        style={{
          background: `url(${window.jsStaticUrl}/assets/Resources/background/home-bg-2.png)`,
        }}
      />
      <div
        className={cn({
          slogan: true,
          [styles["slogan"]]: true,
        })}
      >
        <div
          className={cn({
            "slogan-wrap": true,
            [styles["slogan-wrap"]]: true,
          })}
        >
          <div
            className={styles["slogan-title"]}
            style={{
              transform: `translateY(${isLogin ? 20 : 36}px)`,
            }}
          >
            <h1>同时创造，即时设计</h1>
            {isMobile.phone ? (
              <p className={`${styles["des"]} des`}>
                可云端编辑的专业级 UI 设计工具，
                <br />
                为中国设计师量身打造 Windows 也能用的「协作版 Sketch」
              </p>
            ) : (
              <p className={`${styles["des"]} des`}>
                可云端编辑的专业级 UI 设计工具，为中国设计师量身打造
                <br />
                Windows 也能用的「协作版 Sketch」
              </p>
            )}
            {isMobile.phone && (
              <p
                className={cn({
                  [styles["des"]]: true,
                  [styles["hero-mobile-des"]]: true,
                })}
              >
                💻 请使用电脑端访问
              </p>
            )}
          </div>
          {!isMobile.phone && (
            <RegisterBtn
              maxDescribe={false}
              className={cn({
                [styles["hero-motion-form"]]: true,
                [styles.loginedBtn]: isLogin,
              })}
              style={{
                width: "244px",
                padding: 0,
                height: "52px",
                lineHeight: "52px",
              }}
            />
          )}
        </div>
      </div>
      <div
        className={cn({
          "window-wrap": true,
          [styles["window-wrap"]]: true,
        })}
      >
        <div
          className={cn({
            "window-1": true,
          })}
        >
          <div className={styles.window}>
            {HeroVideoList.map((item, index) => (
              <div
                key={item.poster}
                className={cn({
                  "video-wrap": true,
                  [styles["gsap-video-wrap"]]: true,
                  ["hero-video-" + (index + 1)]: true,
                })}
              >
                <div className={styles["safari-bar"]}>
                  <LazyImg src={safariBar} className="width-full" />
                </div>
                <video
                  ref={videoDom}
                  loop
                  autoPlay
                  muted
                  src={item.src}
                  poster={item.poster}
                  playsInline
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

export default HeroMotion;
