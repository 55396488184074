import { createContext, Dispatch, useContext } from "react";

const TopTipStore = createContext<I_Top_Tip.State | null>(null);

const TopTipDispatchStore = createContext<Dispatch<I_Top_Tip.Actions> | null>(null);

function useTopTipStore() {
  return useContext(TopTipStore) as I_Top_Tip.State;
}

function useTopTipDispatchStore() {
  return useContext(TopTipDispatchStore) as Dispatch<I_Top_Tip.Actions>;
}

export { TopTipStore, TopTipDispatchStore, useTopTipStore, useTopTipDispatchStore };
