const publishStateFn = (): I_Publish.State => {
  return {
    publishedCompTotal: 27923,
    publishedLibTotal: 210,
    publishedTotal: 11466,
    isUpdate: false,
  };
};

let publishState: I_Publish.State = publishStateFn();

const initPublishState = (): I_Publish.State => {
  publishState = publishStateFn();
  return publishState;
};

export { publishState, initPublishState };
