import { noop } from "lodash-es";
import React, { CSSProperties, useMemo } from "react";
import styled from "styled-components";
import SVGInline from "svg-inline-react";
import { preventDefault } from "@utils/event";
import figma from "@pages/Home/figma.svg";

interface I_Props
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  iconSvg: string;
  size?: number | string;
  width?: number | string;
  height?: number | string;
  styles?: CSSProperties;
  styleName?: string;
  hoverBgColor?: string;
  hoverOpacity?: number;
  borderRadius?: string;
  isPreventDefault?: boolean;
  clickFunc?: (...args: any[]) => any;
  topIcon?: boolean;
}
export function BaseIcon(props: I_Props) {
  const {
    iconSvg,
    clickFunc = noop,
    size = 14,
    width,
    height,
    styles,
    styleName,
    hoverBgColor,
    borderRadius,
    hoverOpacity,
    isPreventDefault = false,
    topIcon,
  } = props;

  const computedStyle = useMemo(() => {
    let curWidth = width ?? size;
    let curHeight = height ?? size;
    curWidth = typeof curWidth === "number" ? `${curWidth}px` : curWidth;
    curHeight = typeof curHeight === "number" ? `${curHeight}px` : curHeight;

    let defaultSize = {
      width: curWidth,
      height: curHeight,
    };
    return Object.assign(size ? defaultSize : {}, {
      ...styles,
    });
  }, []);

  const baseIconCss = {
    ...(hoverBgColor ? { hoverBgColor } : {}),
    ...(borderRadius ? { borderRadius } : {}),
    ...(hoverOpacity ? { hoverOpacity } : {}),
  };

  if (!iconSvg) return <></>;

  return (
    <BaseIconCss
      onClick={(e) => {
        if (e && isPreventDefault) preventDefault(e);
        clickFunc(e);
      }}
      className={styleName}
      style={computedStyle}
      {...baseIconCss}
    >
      {topIcon ? (
        // <SVGInline src={iconSvg} />
        <img src={iconSvg} alt="" />
      ) : (
        <div
          className="baseIcon"
          dangerouslySetInnerHTML={{ __html: iconSvg }}
        ></div>
      )}
      {props.children}
    </BaseIconCss>
  );
}

interface BaseIconCssProps {
  hoverBgColor?: string;
  borderRadius?: string;
  hoverOpacity?: number;
}

const BaseIconCss = styled.div<BaseIconCssProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "initial"};

  &:hover {
    opacity: ${(props) =>
      props.hoverOpacity ? `${props.hoverOpacity} !important` : "initial"};
    background-color: ${(props) =>
      props.hoverBgColor ? props.hoverBgColor : "transparent"};
  }

  .baseIcon,
  svg {
  }
  .baseIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
