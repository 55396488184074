const StateFn = (): I_Top_Tip.State => {
  return {
    showTopTip: false,
  };
};

const State = StateFn();


export { State };
