import { BaseIcon } from "@components/BaseIcon";
import { RESOURCE_SVG_LIST } from "@pages/Home/constant";

import styles from "./index.module.less";

interface I_ResourceBtn {
  title: string;
  onClick?: (...args: any[]) => void;
  className: string;
}

function ResourceBtn(props: I_ResourceBtn) {
  const { title, onClick, className } = props;

  const onDown = (e: any) => {
    onClick && onClick(e);
  };

  return (
    <div>
      <div
        className={`${styles["resource-btn-wrap"]} ${className}`}
        onClick={onDown}>
        {title}
        <BaseIcon
          styleName={styles["arrow-right"]}
          iconSvg={RESOURCE_SVG_LIST.arrowRight}
        />
      </div>
    </div>
  );
}

export default ResourceBtn;
