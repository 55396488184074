import { isUndefined } from "lodash-es";
import { goto } from "src/WithRouter";

const AD_SOURCE_ID: string[] = [
  "61f3ad939871ca34de9511d6",
  "6116381f644a4a07465acf1d",
  "610b8f7d8633535893fe132a",
  "61ebcb67b368e1354e837fa4",
  "622566bc518ba27f27ca993c",
  "625791c512ce1735e7020354",
  "62593f771be7d25acb34bb0f",
  "62fb3f86bb359fef33670c1d",
];

const PREFIX_URL = `${(window as any).jsStaticUrl}/assets`;

const CLASSIFY_NAME_MAP: Record<string, string> = {
  app: "APP",
  web: "Web",
  applet: "小程序",
  others: "其它",
};

const CLASSIFY_LIST: any[] = [
  {
    type: "all",
    get name() {
      return "全部";
    },
    id: "",
  },
  {
    type: "ui",
    get name() {
      return "作品";
    },
    id: "5ecf9bbf5d2279732ce0b617",
  },
  {
    type: "uiKit",
    get name() {
      return "设计规范";
    },
    id: "5ecf9bc75d2279732ce0b618",
  },
  {
    type: "page",
    get name() {
      return "页面";
    },
    id: "5ecf9bcd5d2279732ce0b619",
  },
  {
    type: "component",
    get name() {
      return "组件";
    },
    id: "5ecf9bd25d2279732ce0b61a",
  },
  {
    type: "icon",
    get name() {
      return "图标";
    },
    id: "5ecf9bd75d2279732ce0b61b",
  },
  {
    type: "illustration",
    get name() {
      return "插画";
    },
    id: "5ecf9be35d2279732ce0b61d",
  },
  {
    type: "element",
    get name() {
      return "元素";
    },
    id: "5ecf9bdc5d2279732ce0b61c",
  },
  {
    type: "wireframe",
    get name() {
      return "产品原型";
    },
    id: "5ee03daff866ee06e4f36d16",
  },
  {
    type: "mockup",
    name: "Mockup",
    id: "5ecf9be85d2279732ce0b61e",
  },
  {
    type: "portfolio",
    get name() {
      return "作品集";
    },
    id: "62203a9033bbf188d6848f4b",
  },
  {
    type: "whiteBoardSticker",
    name: "白板贴纸",
    id: "64224b8bc302b80df7265469",
  },
];

export enum ResourceType {
  Resource = "resource",
  Plugin = "plugin",
  Widget = "widget",
}

function publishTotalConvert(data: any) {
  let static_data = [
    {
      name: "作品",
      data: Math.round(data.publishedTotal / 1000) + "K+",
    },
    {
      name: "资源库",
      data: Math.round(data.publishedLibTotal / 100) + "00+",
    },
    {
      name: "设计元素",
      data: Math.round(data.publishedCompTotal / 1000) + "K+",
    },
  ];
  return static_data;
}

function getIsAD(resource: Partial<any>) {
  return resource?._id && AD_SOURCE_ID.includes(resource._id);
}

function gotoCommunityPage(category: string, params: any = {}, newTab = false) {
  const paramStr = new URLSearchParams(params).toString();
  const urlParams = `/community?category=${category}${paramStr.length ? "&" + paramStr : ""
    }`;
  if (newTab) {
    open(urlParams);
  } else {
    goto(urlParams);
  }
}

function getPublishedResourceImg(coverImg: string, publishStatus: any) {
  if (!coverImg) return "";
  const suffixUrl = publishStatus === "published" ? "published" : "review";

  return `${PREFIX_URL}/coverImg/${suffixUrl}/${coverImg}`;
}

function getResourceCoverUrl(resource: any, isRecommendResource?: boolean) {
  const {
    _id,
    classifyId,
    publishCode,
    coverImg,
    publishStatus,
    platform,
    number,
    imageSuffix,
    imageType,
    projectImageInfo,
    libraryCoverImg,
    punchCardId,
  } = resource;
  if (isUndefined(resource)) return "";

  if (resource?.coverImgUrl || resource?.cover) {
    // 新补充
    return resource?.coverImgUrl || resource?.cover;
  }

  if (isRecommendResource && libraryCoverImg) {
    // 精品资源且为库时，走这个封面图
    // ${(window as any).jsServerUrl  ${(window as any).jsStaticUrl
    return `${(window as any).jsStaticUrl
      }/assets/library/coverImg/${libraryCoverImg}`;
  }

  if (imageType === "normal") {
    if (punchCardId && resource.category == ResourceType.Resource) {
      if (resource.projects && resource.projects[0]) {
        const { projects } = resource;
        const { coverHash, shortId } = projects[0];
        if (coverHash && shortId) {
          return `${(window as any).jsStaticUrl}/assets/coverImg/${shortId + coverHash
            }.png`;
        }
      }
    }
    if (projectImageInfo) {
      const { coverHash, shortId } = projectImageInfo;
      if (coverHash && shortId) {
        return `${(window as any).jsStaticUrl}/assets/coverImg/${shortId + coverHash
          }.png`;
      }
    }
    return "";
  }

  if (publishCode) {
    // 用户发布的资源
    return coverImg && getPublishedResourceImg(coverImg, publishStatus);
  }

  let classifyName = classifyId?.name;
  if (classifyId?.name == "作品" && platform) {
    classifyName = CLASSIFY_NAME_MAP[platform];
  }

  if (imageSuffix) {
    if (punchCardId)
      return `${(window as any).jsStaticUrl
        }/assets/punchCard/workCoverImage/${_id}${imageSuffix}`;
    return `${(window as any).jsStaticUrl
      }/assets/activity/workCoverImage/${_id}${imageSuffix}`;
  }

  return `${(window as any).jsStaticUrl
    }/assets/Resources/cover/${classifyName}-${number}.png`;
}

function handleGetClassifyIdByType(_classify: string) {
  return CLASSIFY_LIST.find((_val) => _classify == _val.type)?.id;
}

function handleGetResourceLibCompImgUrl(_shortId: string, _coverHash: string, _isLargeImg?: boolean) {
  return `${(window as any).jsStaticUrl}/assets/compImg/${_shortId}${_coverHash}${_isLargeImg ? '_large' : ''}.png`;
}


export { getIsAD, publishTotalConvert, gotoCommunityPage, getResourceCoverUrl, handleGetClassifyIdByType, handleGetResourceLibCompImgUrl };
