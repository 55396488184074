export const footerData = [
  {
    title: "原型工具",
    href: "https://www.xiaopiu.com/",
  },
  {
    title: "PRD 文档",
    href: "https://www.xiaopiu.com/prd",
  },
  {
    title: "私有部署版",
    href: "https://js.design/privatization",
  },
  {
    title: "设计工具",
    path: "/home",
  },
  {
    title: "角色功能介绍",
    path: "/scene",
  },
  {
    title: "向同事介绍我们",
    path: "/recommend",
  },
  {
    title: "帮助中心",
    path: "/help",
  },
  {
    title: "最新功能",
    path: "/update",
  },
  {
    title: "服务条款",
    path: "/terms",
  },
  {
    title: "开发者中心",
    path: "/developer",
  },
  {
    title: "校园计划",
    path: "/SchoolSide",
  },
  {
    title: "关于我们",
    path: "/about",
  },
  {
    title: "加入我们",
    path: "/about",
    anchor: "join",
  },
  {
    title: "成长历程",
    path: "/about",
    anchor: "milestone",
  },
  {
    title: "媒体报道",
    path: "/about",
    anchor: "media",
  },
  {
    title: "Figma",
    href: `${window.jsUrl}/special/figma/`,
  },
  {
    title: "Sketch",
    href: `${window.jsUrl}/special/sketch/`,
  },
  {
    title: "PS",
    href: `${window.jsUrl}/special/ps/`,
  },
  {
    title: "XD",
    href: `${window.jsUrl}/special/xd/`,
  },
  {
    title: "Axure",
    href: `${window.jsUrl}/special/axure/`,
  },
  {
    title: "AI",
    href: `${window.jsUrl}/special/ai/`,
  },
  {
    title: "设计开源月刊",
    path: "/monthly",
    // 设计开源周刊 - 改这里
    period: "202212",
  },
];
