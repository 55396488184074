import cn from "classnames";
import { Link, useNavigate } from "react-router-dom";
import InlineSVG from "svg-inline-react";
import { useCallback, useMemo, useState } from "react";
import { noop } from "lodash-es";

import { HomeRoutes } from "@router/home";
import { ExtraRouteObj } from "@router/index";
import {
  icon_logo,
  text_logo_zhCN,
  icon_arrow,
  notificationsIcon,
} from "@svg/home";
import Button, { ButtonType } from "@components/Button";
import right from "@svg/right.svg";
import LoginInfoBtn from "@pages/Home/Components/LoginInfoBtn/loginInfoBtn";
import { useUserStore } from "src/store/user";
import {
  useNotifications,
  useNotificationsDispatch,
} from "@components/NotificationsPanel/store";
import { BaseIcon } from "@components/BaseIcon";
import LazyImg from "@components/LazyImg";
import { useTopTipStore } from "src/store/top-tip";

import styles from "./index.module.less";

interface PcNavProps {}

const renderRoute = (
  routes: ExtraRouteObj[],
  handleGo: (path: string, isExternal: boolean | undefined) => void
): any[] => {
  return routes.map((item) => {
    const _key = item.path + item.name;
    const { icon } = item;
    // 判断展示icon还是文本
    const showItem = icon ? <InlineSVG src={icon} /> : item.name;
    // 判断是否展示路由
    if (
      item.show === false ||
      (item.showCustom && item.showCustom?.() === false)
    )
      return "";
    // 子路由集合
    if (item.children) {
      const navGroupItemBodyWith =
        item.children.length >= 3
          ? 400 * Math.ceil(item.children.length / 3)
          : 400;
      return (
        <div key={_key} className={`${styles.navItem} ${styles.navGroupItem}`}>
          <div className={styles.navGroupItemParent}>
            <p>{showItem}</p>
            <InlineSVG src={icon_arrow} />
          </div>
          <div
            className={styles.navGroupItemBody}
            style={{
              width: item.extraContent
                ? navGroupItemBodyWith + 220
                : navGroupItemBodyWith,
            }}>
            <div className={styles.navGroupItemBodyItem}>
              {renderRoute(item.children, handleGo)}
            </div>
            <div>{item?.extraContent?.()}</div>
          </div>
        </div>
      );
    } else {
      const { isExternal, description, name, icon, clickFunc = noop } = item;
      // children里的作为单独卡片展示
      if (description) {
        return (
          <div key={_key} className={styles.navChild}>
            <div
              className={styles.navChildContainer}
              onClick={() => handleGo(item.path!, isExternal)}
            >
              <span>
                <LazyImg src={icon} alt="icon" />
              </span>
              <div>
                <p className={styles.title}>{name}</p>
                <p className={styles.description}>{description}</p>
              </div>
              <div className={styles.rightArrow}>
                <LazyImg src={right} alt="icon" />
              </div>
            </div>
          </div>
        );
      }
      // 常规路由
      return (
        <div key={_key} className={styles.navItem} onClick={clickFunc}>
          {/* 判断是外部跳转还是内部跳转 */}
          {isExternal ? (
            <a href={item.path!} target="_blank" rel="noreferrer">
              {showItem}
            </a>
          ) : (
            <Link to={item.path!}>{showItem}</Link>
          )}
        </div>
      );
    }
  });
};

const PcNav = (props: PcNavProps) => {
  const { _id } = useUserStore();
  const { isShow: isShowNotificationsPanel, readAllMessageStatus } =
    useNotifications();
  const notificationsDispatch = useNotificationsDispatch();
  const [isShowNewsPanel, setIsShowNewsPanel] = useState(false);
  const { showTopTip } = useTopTipStore();

  const isLogin = !!_id;

  const clickNotificationsIcon = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (isShowNotificationsPanel) {
        notificationsDispatch({
          type: "toggleShow",
          payload: {
            isShow: false,
          },
        });
      } else {
        notificationsDispatch({
          type: "toggleShow",
          payload: {
            isShow: true,
            hoverElementPos: (
              e.target as HTMLDivElement
            ).getBoundingClientRect(),
          },
        });
      }
    },
    [isShowNotificationsPanel]
  );

  const dynamicStyles = useMemo(() => {
    return {
      top: showTopTip ? "40px" : "0px",
    };
  }, [showTopTip]);

  const n = useNavigate();
  const handleGo = (path = "", isExternal = false) => {
    if (isExternal) {
      window.open(path);
    } else {
      path && n(path);
    }
  };
  return (
    <nav id="navWrapper" className={`global-nav ${styles.navContainer}`} style={dynamicStyles}>
      <div className={'view-1 commWrap'}>
        <div className={styles.logo}>
          <InlineSVG src={icon_logo} />
          <InlineSVG src={text_logo_zhCN} />
        </div>
        <div className={styles.nav}>{renderRoute(HomeRoutes, handleGo)}</div>
        <div className={styles.operation}>
          {isLogin && (
            <div
              className={cn({
                [styles.notificationsIcon]: true,
                [styles.notificationsIconBg]: isShowNewsPanel,
              })}
              id={"notificationsIconPos"}
              onClick={clickNotificationsIcon}>
              <BaseIcon iconSvg={notificationsIcon} size={32} />

              {readAllMessageStatus && <div className="spot" />}
            </div>
          )}

          {!isLogin ? (
            <>
              <Link to="/login">
                <Button className={styles.login}>登录</Button>
              </Link>
              <Link to="/login">
                <Button type={ButtonType.primary}>免费使用</Button>
              </Link>
            </>
          ) : (
            <LoginInfoBtn />
          )}
        </div>
      </div>
    </nav>
  );
};

export default PcNav;
